import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { theme } from '../config'
import { HStack, VStack, Text, Spacer, TextInput } from '../components'
import { navigate } from '@reach/router'
import Image from '../assets/images/headerlogo.png'
import { loginUser } from '../hooks/useAuth'
import { PasswordField } from '../components/TextInput'

const LoginContainer = styled.div`
	width: 50%;
	position: absolute;
	left: 25%;
	top: 25%;
`

const TitleContainer = styled.div`
	display: flex;
	align-items: top;
`

const Button = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 50px;
	border-radius: ${theme.radii.x2};
	box-shadow: ${theme.boxShadow};
	background: ${theme.colors.primary100};
	color: ${theme.colors.white100};
	text-align: center;
`

const Login = ({}) => {
	const [email, setEmail] = useState()
	const [pass, setPass] = useState()
	const [error, setError] = useState(false)

	useEffect(() => {
		localStorage.setItem('navTab', 0)
		localStorage.setItem('accessToken', null)
		localStorage.setItem('hpNationality', null)
		localStorage.setItem('refreshToken', null)
		localStorage.setItem('tokenCreated', null)
		localStorage.setItem('erNationality', null)
		localStorage.setItem('hpAthleteId', null)
	}, [])

	const handleLogin = (email, pass) => {
		setError(false)
		loginUser(email, pass)
			.then((response) => {
				localStorage.setItem(
					'accessToken',
					response.data.data.attributes.access_token
				)
				localStorage.setItem(
					'hpNationality',
					response.data.data.attributes.hp_nationality
				)
				localStorage.setItem(
					'refreshToken',
					response.data.data.attributes.refresh_token
				)
				localStorage.setItem('tokenCreated', Date.now())
				if (response.data.data.attributes.hp_athlete_id) {
					localStorage.setItem(
						'hpAthleteId',
						response.data.data.attributes.hp_athlete_id
					)
				} else {
					localStorage.setItem('hpAthleteId', null)
				}
			})
			.then(() => navigate('/team'))
			.catch((error) => {
				setError(error)
			})
	}

	// const { loginUser, error } = useAuth()
	return (
		<LoginContainer>
			<TitleContainer>
				<img src={Image} width="50px" height="50px" />
				<Spacer width="x6" />
				<Text size="x6" weight="semibold">
					Welcome to the EquiRatings HP Centre
				</Text>
			</TitleContainer>
			<Spacer height="x6" />
			<Text size="x4" weight="semibold">
				Enter your Email
			</Text>
			<Spacer height="x2" />
			<TextInput
				placeholder="example@example.com"
				onChange={(e) => setEmail(e.target.value)}
			/>
			<Spacer height="x4" />
			<Text size="x4" weight="semibold">
				Enter your Password
			</Text>
			<Spacer height="x2" />
			<PasswordField
				placeholder="Password"
				onChange={(e) => setPass(e.target.value)}
			/>
			<Spacer height="x4" />
			<Button
				onClick={async () => {
					handleLogin(email, pass)
				}}
			>
				<Text size="x4" weight="semibold" color="white100">
					Login
				</Text>
			</Button>
			{error ? (
				<>
					<Spacer height="x4" />
					<Text size="x4" weight="semibold" color="red100">
						There was an issue with your login details, please try again.
					</Text>
				</>
			) : null}
		</LoginContainer>
	)
}

export default Login
