import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { navigate } from '@reach/router'
import ReactTooltip from 'react-tooltip'

import {
	HStack,
	LineChart,
	Dropdown,
	VStack,
	Text,
	Spacer,
	Table,
	Layout,
} from '../components'

import Question from '../assets/icons/Question'
import { useResource } from '../hooks'
import { theme } from '../config'
import { formatToPercentage } from '../utilities/helperFunctions'
import envVars from '../utilities/envVars'

const ExpectedBox = styled.div`
	width: 175px;
	height: 175px;
	display: flex;
	align-items: center;
	background: ${theme.colors.white100};
	border-radius: ${theme.radii.x4};
	box-shadow: ${theme.boxShadow};
`

const ExpectedsContainer = styled.div`
	width: 90%;
	display: flex;
	align-items: center;
`

const TitleContainer = styled.div`
	display: flex;
	width: 57%;
	justify-content: space-between;
	align-items: flex-end;
`

const DropdownContainer = styled.div`
	width: 40%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`

const Tab = styled.div`
	margin-right: ${theme.spacing.x4};
	margin-top: ${theme.spacing.x2};
	margin-bottom: ${theme.spacing.x2};
	padding: ${theme.spacing.x2} ${theme.spacing.x6};
	border-radius: ${({ active }) => (active ? theme.radii.x2 : 'none')};
	background: ${({ active }) => (active ? theme.colors.white100 : 'none')};
	box-shadow: ${({ active }) => (active ? theme.boxShadow : 'none')};
`

const HorseView = (props) => {
	const base_url = 'dashboard/high_performance'
	const selectYearFromInitial = envVars.currentYear - 3
	const selectYearToInitial = envVars.currentYear

	const majorClasses = envVars.majorClasses.map((lvl) => {
		return { title: lvl.key, level: lvl.value }
	})

	const fiveStarClass = majorClasses.find((lvl) => lvl.level === 13)

	const [selectedYearFrom, setYearFrom] = useState(selectYearFromInitial)
	const [selectedYearTo, setYearTo] = useState(selectYearToInitial)
	const [activeTab, setActiveTab] = useState(0)
	const horseId = props.horseId
	const horseName = props.location.state.name
	const athlete = props.location.state.athlete
	const horseElo = props.location.state.elo

	const [selectedLevel, setLevel] = useState(fiveStarClass)

	const { all: statOverviewData, getAll: getStatsOverview } = useResource(
		`${base_url}/recent_form` + `?horse_id=${horseId}` + `&return_top=50`
	)

	const { all: eloData, getAll: getHorseElos } = useResource(
		`${base_url}/elo_trend_horse` +
			`?horse_id=${horseId}` +
			`&return_top=10` +
			`&decimal_places=0`
	)

	const { all: horseExpecteds, getAll: getHorseExpecteds } = useResource(
		`${base_url}/expected_values_horse` +
			`?horse_id=${horseId}` +
			`&er_levels[]=${selectedLevel.level}` +
			`&decimal_places=3`
	)

	let erLevels = ''
	envVars.allClassLevels.forEach((item) => {
		erLevels = `${erLevels}&er_levels[]=${item}`
	})

	const { all: classOverviewObj, getAll: getClassOverview } = useResource(
		`${base_url}/class_overview` +
			`?horse_id=${horseId}` +
			`&year_from=${selectedYearFrom}` +
			`&year_to=${selectedYearTo}` +
			`${erLevels}` +
			`&decimal_places=0`
	)

	useEffect(() => {
		getStatsOverview({ authToken: true })
		getHorseElos({ authToken: true })
	}, [getStatsOverview, getHorseElos])

	useEffect(() => {
		getClassOverview({ authToken: true })
	}, [getClassOverview])

	useEffect(() => {
		getHorseExpecteds({ authToken: true })
	}, [getHorseExpecteds])

	const classOverviewData = useMemo(
		() => (classOverviewObj && classOverviewObj.data) || []
	)

	const statOverview = useMemo(
		() => (statOverviewData && statOverviewData.data) || []
	)

	const recentFormAnalysisColumns = useMemo(() => [
		{
			Header: 'Event',
			textAlign: 'left',
			columns: [
				{
					Header: 'Venue',
					textAlign: 'left',
					accessor: 'attributes.venue',
					isBold: true,
					cursor: 'pointer',
					Cell: (props) => <div>{props.value}</div>,
				},
				{
					Header: 'Class',
					accessor: 'attributes.class',
				},
				{
					Header: 'Date',
					accessor: 'attributes.competitionDate',
				},
			],
		},
		{
			Header: 'EquiRatings HP Analysis',
			columns: [
				{
					Header: 'ADS',
					sortType: 'alphanumericFalsyLast',
					accessor: 'attributes.edrDiffAvg',
					Cell: (props) => (
						<div>{props.value || props.value === 0 ? props.value : '-'}</div>
					),
				},
				{
					Header: 'XJS',
					sortType: 'alphanumericFalsyLast',
					accessor: 'attributes.excjDiffAvg',
					Cell: (props) => (
						<div>
							{props.value || props.value === 0
								? formatToPercentage(props.value, 0)
								: '-'}
						</div>
					),
				},
				{
					Header: 'XTS',
					sortType: 'alphanumericFalsyLast',
					accessor: 'attributes.exct50DiffAvg',
					Cell: (props) => (
						<div>{props.value || props.value === 0 ? props.value : '-'}</div>
					),
				},
				{
					Header: 'SJS',
					sortType: 'alphanumericFalsyLast',
					accessor: 'attributes.esjDiffAvg',
					Cell: (props) => (
						<div>{props.value || props.value === 0 ? props.value : '-'}</div>
					),
				},
				{
					Header: 'Winning HPR',
					accessor: 'attributes.winningHpr',
					Cell: (props) => (
						<div>{props.value || props.value === 0 ? props.value : '-'}</div>
					),
				},
			],
		},
	])

	const recentFormColumns = useMemo(() => [
		{
			Header: 'Event',
			textAlign: 'left',
			columns: [
				{
					Header: 'Venue',
					textAlign: 'left',
					accessor: 'attributes.venue',
					isBold: true,
					cursor: 'pointer',
					Cell: (props) => (
						<div
							style={{
								cursor: 'pointer',
								textDecoration: 'underline',
							}}
							onClick={() => {
								localStorage.setItem('navTab', 3)
								navigate(`/venues/competition/${props.value}`, {
									state: {
										id: props.row.original.attributes.competitionId,
										name: `${props.value} ${props.row.original.attributes.class}`,
										date: props.row.original.attributes.competitionDate,
									},
								})
							}}
						>
							{props.value}
						</div>
					),
				},
				{
					Header: 'Class',
					accessor: 'attributes.class',
				},
				{
					Header: 'Date',
					accessor: 'attributes.competitionDate',
				},
			],
		},
		{
			Header: 'Dressage',
			columns: [
				{
					Header: 'Score',
					accessor: 'attributes.drScore',
					sortType: 'alphanumericFalsyLast',
					Cell: (props) => (
						<div>{props.value || props.value === 0 ? props.value : '-'}</div>
					),
				},
			],
		},
		{
			Header: 'Cross Country',
			columns: [
				{
					Header: 'Jump',
					sortType: 'alphanumericFalsyLast',
					accessor: 'attributes.xcJump',
					Cell: (props) => (
						<div>{props.value || props.value === 0 ? props.value : '-'}</div>
					),
				},
				{
					Header: 'Time',
					sortType: 'alphanumericFalsyLast',
					accessor: 'attributes.xcTime',
					Cell: (props) => (
						<div>{props.value || props.value === 0 ? props.value : '-'}</div>
					),
				},
			],
		},
		{
			Header: 'Show Jumping',
			columns: [
				{
					Header: 'Jump',
					sortType: 'alphanumericFalsyLast',
					accessor: 'attributes.sjJump',
					Cell: (props) => (
						<div>{props.value || props.value === 0 ? props.value : '-'}</div>
					),
				},
				{
					Header: 'Time',
					sortType: 'alphanumericFalsyLast',
					accessor: 'attributes.sjTime',
					Cell: (props) => (
						<div>{props.value || props.value === 0 ? props.value : '-'}</div>
					),
				},
			],
		},
		{
			Header: 'Total',
			sortType: 'alphanumericFalsyLast',
			accessor: 'attributes.finalScore',
			Cell: (props) => (
				<div>{props.value || props.value === 0 ? props.value : '-'}</div>
			),
		},
		{
			Header: 'Pos',
			accessor: 'attributes.finalPosition',
			sortType: 'alphanumericFalsyLast',
			Cell: (props) => (
				<div>
					{props.value === null
						? props.row.original.attributes.finalStatus
						: props.value}
				</div>
			),
		},
		{
			Header: 'HPR',
			sortType: 'alphanumericFalsyLast',
			accessor: 'attributes.hpr',
			Cell: (props) => (
				<div>{props.value || props.value === 0 ? props.value : '-'}</div>
			),
		},
	])
	const classOverviewColumns = useMemo(() => [
		{
			Header: 'Class',
			textAlign: 'left',
			accessor: 'attributes.class',
			isBold: true,
			cursor: 'pointer',
			Cell: (props) => <div>{props.value}</div>,
		},
		{
			Header: 'Runs',
			accessor: 'attributes.runs',
			sortType: 'alphanumericFalsyLast',
		},
		{
			Header: 'Completion',
			accessor: 'attributes.compeletions',
			sortType: 'alphanumericFalsyLast',
		},
		{
			Header: 'WD',
			accessor: 'attributes.wdPerecentage',
			Cell: (props) => <div>{props.value}%</div>,
			sortType: 'alphanumericFalsyLast',
		},
		{
			Header: 'Avg Dr',
			accessor: 'attributes.drScoreAvg',
			sortType: 'alphanumericFalsyLast',
		},
		{
			Header: 'XCJ Clear',
			accessor: 'attributes.xcjClearPercentage',
			Cell: (props) => <div>{props.value}%</div>,
			sortType: 'alphanumericFalsyLast',
		},
		{
			Header: 'XCT Clear',
			accessor: 'attributes.xctClearPercentage',
			Cell: (props) => <div>{props.value}%</div>,
			sortType: 'alphanumericFalsyLast',
		},
		{
			Header: 'XCT Avg Pens',
			accessor: 'attributes.xcTimeAvg',
			sortType: 'alphanumericFalsyLast',
		},
		{
			Header: 'SJ Clear',
			accessor: 'attributes.sjClearPercentage',
			Cell: (props) => <div>{props.value}%</div>,
			sortType: 'alphanumericFalsyLast',
		},
		{
			Header: 'SJJ Avg',
			accessor: 'attributes.sjJumpAvg',
			sortType: 'alphanumericFalsyLast',
		},
		{
			Header: 'Avg FS',
			accessor: 'attributes.finalScoreAvg',
			sortType: 'alphanumericFalsyLast',
		},
		{
			Header: 'Avg HPR',
			accessor: 'attributes.hprAvg',
			Cell: (props) => <div>{props.value || '-'}</div>,
			sortType: 'alphanumericFalsyLast',
		},
	])

	const isAthlete = localStorage.getItem('hpAthleteId')
	return (
		<Layout>
			{eloData && classOverviewObj ? (
				<>
					<Text size="x6" weight="semibold">
						{horseName}
					</Text>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Text size="x4" weight="normal" color="primary50">
							{athlete}
						</Text>
						<Spacer width="x3" />
						<Text size="x5" weight="normal" color="primary50">
							|
						</Text>
						<Spacer width="x3" />
						<Text size="x4" weight="normal" color="primary50">
							Current Horse Elo: {horseElo}
						</Text>
					</div>
					<Spacer height="x8" />
					{isAthlete === 'null' && (
						<TitleContainer>
							<HStack align="center">
								<Text size="x5" weight="semibold">
									Expected Values
								</Text>
								<Spacer width="x4" />
								<Question data-tip data-for="expecteds" />
							</HStack>
							<DropdownContainer>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<Text size="x4" weight="semibold">
										Select Level
									</Text>
									<Spacer height="x1" />
									<Dropdown
										onChange={(level) => setLevel(level)}
										readOnly={true}
										items={majorClasses}
										itemToString={(level) => level.title}
										initialItem={fiveStarClass}
										handleFilter={() => () => true}
										displaySuggestion={(level) => (
											<Text
												size={theme.fontScale.x2}
												weight={theme.fontWeight.normal}
											>
												{level.title}
											</Text>
										)}
									/>
								</div>
							</DropdownContainer>
						</TitleContainer>
					)}
					{isAthlete === 'null' && (
						<>
						<Spacer height="x4" />
							{horseExpecteds && horseExpecteds.data.length > 0 ? (
								<ExpectedsContainer>
									<ExpectedBox>
										<VStack align="center">
											<Text size="x6" weight="semibold">
												{formatToPercentage(
													horseExpecteds.data[0].attributes.xcjClear,
													0
												)}
											</Text>
											<Spacer height="x2" />
											<Text
												size="x3"
												weight="normal"
												color="primary50"
												align="center"
												style={{ width: '85%' }}
											>
												Reliability Rating
											</Text>
										</VStack>
									</ExpectedBox>
									<Spacer width="x4" />
									<ExpectedBox>
										<VStack align="center">
											<Text size="x6" weight="semibold">
												{horseExpecteds.data[0].attributes.drScore}
											</Text>
											<Spacer height="x2" />
											<Text
												size="x3"
												weight="normal"
												color="primary50"
												align="center"
												style={{ width: '85%' }}
											>
												Expected DR Score
											</Text>
										</VStack>
									</ExpectedBox>
									<Spacer width="x4" />
									<ExpectedBox>
										<VStack align="center">
											<Text size="x6" weight="semibold">
												{horseExpecteds.data[0].attributes.xcTime}
											</Text>
											<Spacer height="x2" />
											<Text
												size="x3"
												weight="normal"
												color="primary50"
												align="center"
												style={{ width: '85%' }}
											>
												Expected XCT
											</Text>
										</VStack>
									</ExpectedBox>
									<Spacer width="x4" />
									<ExpectedBox>
										<VStack align="center">
											<Text size="x6" weight="semibold">
												{horseExpecteds.data[0].attributes.sjScore}
											</Text>
											<Spacer height="x2" />
											<Text
												size="x3"
												weight="normal"
												color="primary50"
												align="center"
												style={{ width: '85%' }}
											>
												Expected SJ Pens
											</Text>
										</VStack>
									</ExpectedBox>
									<Spacer width="x4" />
								</ExpectedsContainer>
							) : (
								<>
									<Spacer height="x4" />
									<Text size="x4" weight="semibold" color="primary30">
										There are no expected values for this horse, at this level.
									</Text>
									<Spacer height="x4" />
								</>
							)}
						</>
					)}
					<Spacer height="x8" />
					<Spacer height="x8" />
					<LineChart
						tooltipId="horseElo"
						tooltipText={() => (
							<Text weight="semibold" size="x2" color="white100">
								Each horse has an Elo rating which measures their
								competitiveness. <Spacer height="x2" />
								Their rating goes up or down after each FEI run (except for WDs
								before XC). The higher the Elo the better.
								<Spacer height="x2" />
								<Text size="x2" weight="semibol" color="secondary100">
									The top 10% of 4* and 5* horses have Elos of 600+.
								</Text>
								<Spacer height="x2" />
								This graph shows the horse’s Elo after each FEI run.
								<Spacer height="x2" />
								<HStack align="center">
									<Text size="x2" color="secondary100" weight="semibold">
										Hover
									</Text>
									<Spacer width="x1" />
									over any data point to see the competition name and the exact
									Elo rating.
								</HStack>
							</Text>
						)}
						xAxisFormat={'%d/%m/%Y'}
						subTitleLabel1={'Elo'}
						subTitleLabel2={'Competition'}
						showLegend={false}
						title={'Elo Trend (last 10 competitions)'}
						data={[
							eloData.data.map((item) => {
								return {
									label: new Date(item.attributes.date),
									subTitle: item.attributes.competition,
									value: item.attributes.elo,
								}
							}),
						]}
						width={925}
						height={300}
					/>
					<Spacer height="x8" />
					<Spacer height="x8" />
					<TitleContainer>
						<HStack align="center">
							<Text size="x5" weight="semibold">
								Statistical Overview
							</Text>
							<Spacer width="x4" />
							<Question data-tip data-for="stat-overview" />
						</HStack>
					</TitleContainer>
					<HStack align="center" justify="space-between">
						<div style={{ display: 'flex' }}>
							<Tab active={activeTab === 0} onClick={() => setActiveTab(0)}>
								<Text size="x4" weight="semibold" cursor={'pointer'}>
									Class Overview
								</Text>
							</Tab>
							<Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>
								<Text size="x4" weight="semibold" cursor={'pointer'}>
									Recent Form
								</Text>
							</Tab>
							<Tab active={activeTab === 2} onClick={() => setActiveTab(2)}>
								<Text size="x4" weight="semibold" cursor={'pointer'}>
									Field Analysis
								</Text>
							</Tab>
						</div>
						{activeTab === 0 && (
							<DropdownContainer>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<Text size="x4" weight="semibold">
										Year From
									</Text>
									<Spacer height="x1" />
									<Dropdown
										onChange={(year) => setYearFrom(year)}
										readOnly={true}
										items={envVars.years}
										itemToString={(year) => year}
										initialItem={selectYearFromInitial}
										handleFilter={() => () => true}
										displaySuggestion={(year) => (
											<Text
												size={theme.fontScale.x2}
												weight={theme.fontWeight.normal}
											>
												{year}
											</Text>
										)}
									/>
								</div>
								<Spacer width="x4" />
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<Text size="x4" weight="semibold">
										Year To
									</Text>
									<Spacer height="x1" />
									<Dropdown
										onChange={(year) => setYearTo(year)}
										readOnly={true}
										items={envVars.years}
										itemToString={(year) => year}
										initialItem={selectYearToInitial}
										handleFilter={() => () => true}
										displaySuggestion={(year) => (
											<Text
												size={theme.fontScale.x2}
												weight={theme.fontWeight.normal}
											>
												{year}
											</Text>
										)}
									/>
								</div>
								<Spacer height="x2" />
							</DropdownContainer>
						)}
					</HStack>
					<Spacer height="x2" />
					{activeTab === 1 ? (
						<Table
							columns={recentFormColumns}
							data={statOverview}
							defaultSort={'attributes.competitionDate'}
						/>
					) : activeTab === 2 ? (
						<Table columns={recentFormAnalysisColumns} data={statOverview} />
					) : (
						<Table
							columns={classOverviewColumns}
							data={classOverviewData}
							defaultSort={'attributes.hprAvg'}
							showSeeMore={false}
						/>
					)}
					<ReactTooltip
						id="expecteds"
						place="right"
						effect="solid"
						multiline={true}
						backgroundColor={theme.colors.primary100}
						textColor={theme.colors.white100}
					>
						<Text weight="semibold" size="x2" color="white100">
							<HStack align="center">
								You can
								<Spacer width="x1" />
								<Text size="x2" color="secondary100" weight="semibold">
									select a level
								</Text>
								<Spacer width="x1" />
								to see the Expected Values for that level.
							</HStack>
							<Spacer height="x2" />
							Reliability Rating is XCJ reliability.
							<Spacer height="x2" />
							Expected SJ Pens represents jumping penalties only
						</Text>
					</ReactTooltip>

					<ReactTooltip
						id="stat-overview"
						place="right"
						effect="solid"
						multiline={true}
						backgroundColor={theme.colors.primary100}
						textColor={theme.colors.white100}
					>
						{activeTab === 0 ? (
							<Text weight="semibold" size="x2" color="white100">
								<Text size="x2" weight="bold" color="white100">
									Class Overview
								</Text>
								<Spacer height="x3" />
								<HStack>
									You can
									<Spacer width="x1" />
									<Text size="x2" color="secondary100" weight="semibold">
										sort
									</Text>
									<Spacer width="x1" />
									by any column by clicking on the column header.
								</HStack>
								<Spacer height="x2" />
								SJ Clear includes jumping and time penalties.
								<Spacer height="x2" />
								Avg HPR: Each run gets an EquiRatings High Performance Rating
								(HPR), the closer to 100 the better.
								<Spacer height="x2" />
								We show the average HPR at the given level.
							</Text>
						) : activeTab === 1 ? (
							<Text weight="semibold" size="x2" color="white100">
								<Text size="x2" weight="bold" color="white100">
									Recent Form
								</Text>
								<Spacer height="x3" />
								<HStack>
									You can
									<Spacer width="x1" />
									<Text size="x2" color="secondary100" weight="semibold">
										sort
									</Text>
									<Spacer width="x1" />
									by any column by clicking on the column header.
								</HStack>
								<Spacer height="x2" />
								Each run gets an EquiRatings High Performance Rating (HPR), the
								closer to 100 the better.
								<Spacer height="x2" />
								Alongside their score in each phase, we show you the HPR from
								each result.
							</Text>
						) : (
							<Text weight="semibold" size="x2" color="white100">
								<Text size="x2" weight="bold" color="white100">
									Field Analysis
								</Text>
								<Spacer height="x3" />
								<HStack>
									You can
									<Spacer width="x1" />
									<Text size="x2" color="secondary100" weight="semibold">
										sort
									</Text>
									<Spacer width="x1" />
									by any column by clicking on the column header.
								</HStack>
								<Spacer height="x2" />
								<HStack>
									<Text size="x2" color="secondary100" weight="semibold">
										ADS:
									</Text>
									<Spacer width="x1" />
									The difference between actual DR scores and expected DR
									scores. A positive number means actual DR scores were, on
									average, higher than expected DR scores (and vice versa).
								</HStack>
								<Spacer height="x2" />
								<HStack>
									<Text size="x2" color="secondary100" weight="semibold">
										XJS:
									</Text>
									<Spacer width="x1" />
									The difference between the actual XCJ clear rate and the
									expected XCJ clear rate. A positive number means the actual
									XCJ clear rate was higher than expected (and vice versa).
								</HStack>
								<Spacer height="x2" />
								<HStack>
									<Text size="x2" color="secondary100" weight="semibold">
										XTS:
									</Text>
									<Spacer width="x1" />
									The difference between the actual XCT penalties and the
									expected XCT penalties (based on the top 50% of the field). A
									positive number means the actual XCT penalties were, on
									average, higher than expected (and vice versa).
								</HStack>
								<Spacer height="x2" />
								<HStack>
									<Text size="x2" color="secondary100" weight="semibold">
										SJS:
									</Text>
									<Spacer width="x1" />
									The difference between the actual SJJ penalties and the
									expected SJJ penalties. A positive number means the actual SJJ
									penalties were, on average, higher than expected (and vice
									versa).
								</HStack>
								<Spacer height="x2" />
								<HStack>
									<Text size="x2" color="secondary100" weight="semibold">
										Winning HPR:
									</Text>
									<Spacer width="x1" />
									Each run gets an EquiRatings High Performance Rating (HPR),
									the closer to 100 the better.
									<Spacer width="x1" />A Winning HPR is the HPR of a
									competition’s winning performance.
								</HStack>
							</Text>
						)}
					</ReactTooltip>
				</>
			) : null}
		</Layout>
	)
}

export default HorseView
