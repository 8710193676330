import axios from 'axios'
const instance = () => {
  const HOST_URL = 'https://zoneanalysis-dash.herokuapp.com'
  const options = {
    baseURL: `${HOST_URL}`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }
  const client = axios.create(options)
  return client
}

export const zoneAnalysisAuth = () => {
  return instance().post('/login', {
    username: 'ZhhkhreUsmKHTSwYH9UP',
    password: 'TqGPhT2tvOBMts4CSFoy',
  })
}
