import React from 'react'
import { Layout } from '../components'
import { teamPerfAuth } from '../utilities/teamPerfAuth'

class TeamSim extends React.Component {
  render() {
    // teamPerfAuth()
    //   .then((response) => console.log(response))
    //   .catch((error) => console.log(error))

    return (
      <Layout>
        <div
          style={{
            height: '3000px',
            margin: '0 auto',
            backgroundColor: 'transparent',
          }}
        >
          <h1 style={{ marginBottom: '3%', color: 'rgba(26, 56, 82, 1)' }}>
            Welcome to the EquiRatings Team Simulator.
          </h1>
          <p style={{ fontSize: '18px', color: 'rgba(26, 56, 82, 1)' }}>
            EquiRatings are proud to introduce the EquiRatings Team Simulator.
            Based on your teams expected performance in each phase, our model
            will simulate a competition 500,000 times and show you each
            combinations scoring distribution for all three phases and your
            teams combined score distribution. As well as this, your teams
            percentage chance of hitting three different benchmarks, set by you,
            are also shown.
          </p>
          <iframe
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-downloads"
            width="100%"
            height="100%"
            scrolling="no"
            frameBorder="0"
            src="https://team-performance-dash.herokuapp.com/team-perf"
          />
        </div>
      </Layout>
    )
  }
}

export default TeamSim
