import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { navigate } from '@reach/router'

import {
  HStack,
  Dropdown,
  Text,
  Spacer,
  MultiSelectDropdown,
  Table,
  Layout,
} from '../components'
import Question from '../assets/icons/Question'

import { useResource } from '../hooks'
import { theme } from '../config'
import envVars from '../utilities/envVars'

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const DropdownContainer = styled.div`
  width: ${({ width }) => width || '40%'};
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

const Tab = styled.div`
  margin-right: ${theme.spacing.x4};
  margin-top: ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x2};
  padding: ${theme.spacing.x2} ${theme.spacing.x6};
  border-radius: ${({ active }) => (active ? theme.radii.x2 : 'none')};
  background: ${({ active }) => (active ? theme.colors.white100 : 'none')};
  box-shadow: ${({ active }) => (active ? theme.boxShadow : 'none')};
`

const AthleteView = (props) => {
  const base_url = 'dashboard/high_performance';
  const selectYearFromInitial = envVars.currentYear - 3;
  const selectYearToInitial = envVars.currentYear;

  const [selectedYearFrom, setYearFrom] = useState(selectYearFromInitial)
  const [selectedYearTo, setYearTo] = useState(selectYearToInitial)
  const [hrrYearFrom, setHrrYearFrom] = useState(selectYearFromInitial)
  const [hrrYearTo, setHrrYearTo] = useState(selectYearToInitial)
  const [activeTab, setActiveTab] = useState(0)
  const [selectedLevel, setLevel] = useState(envVars.majorClasses)
  const athleteId = props.athleteId
  const athleteName = props.location.state.name

  const removeClass = (index, classList, setClassListFunc) => {
    if (classList.length > 1) {
      const newArr = [...classList]
      newArr.splice(index, 1)
      setClassListFunc(newArr)
    }
  }

  const addNewClass = (level, classList, setClassListFunc) => {
    const someArr = classList.map(lvl => lvl.value)
    const isDuplicate = someArr.includes(level.value)
    if (!isDuplicate) {
      setClassListFunc([...classList, level])
    }
  }

  let levelsMapped = ''
  selectedLevel.forEach((item) => {
    levelsMapped = `${levelsMapped}&er_levels[]=${item.value}`
  })

  const { all: statOverviewData, getAll: getStatsOverview } =
    useResource(
      `${base_url}/recent_form` +
      `?athlete_id=${athleteId}` +
      `&return_top=50`
    )

  const { all: highestRatedResults, getAll: getHighestRatedResults } =
    useResource(
      `${base_url}/highest_rated_results_athlete` +
      `?athlete_id=${athleteId}` +
      `&return_top=10` +
      `&decimal_places=0` +
      `${levelsMapped}` +
      `&year_from=${hrrYearFrom}` +
      `&year_to=${hrrYearTo}`
    )

  let erLevels = '';
  envVars.allClassLevels.forEach((lvl) => {
    erLevels = `${erLevels}&er_levels[]=${lvl}`
  });

  const { all: classOverviewObj, getAll: getClassOverview } =
    useResource(
      `${base_url}/class_overview` +
      `?athlete_id=${athleteId}` +
      `&year_from=${selectedYearFrom}` +
      `&year_to=${selectedYearTo}` +
      `${erLevels}` +
      `&decimal_places=0`
    )

  useEffect(() => {
    getStatsOverview({ authToken: true })
  }, [getStatsOverview])

  useEffect(() => {
    getHighestRatedResults({ authToken: true })
  }, [getHighestRatedResults])

  useEffect(() => {
    getClassOverview({ authToken: true })
  }, [getClassOverview])

  const classOverviewData = useMemo(
    () => (classOverviewObj && classOverviewObj.data) || []
  )

  const ratedResultsData = useMemo(
    () => (highestRatedResults && highestRatedResults.data) || []
  )
  const statOverview = useMemo(
    () => (statOverviewData && statOverviewData.data) || []
  )

  const classOverviewColumns = useMemo(() => [
    {
      Header: 'Class',
      textAlign: 'left',
      accessor: 'attributes.class',
      isBold: true,
      cursor: 'pointer',
      Cell: (props) => <div>{props.value}</div>,
    },
    {
      Header: 'Runs',
      accessor: 'attributes.runs',
      sortType: 'alphanumericFalsyLast',
    },
    {
      Header: 'Completion',
      accessor: 'attributes.compeletions',
      sortType: 'alphanumericFalsyLast',
    },
    {
      Header: 'WD',
      accessor: 'attributes.wdPerecentage',
      sortType: 'alphanumericFalsyLast',
      Cell: (props) => <div>{props.value}%</div>,
    },
    {
      Header: 'Avg Dr',
      accessor: 'attributes.drScoreAvg',
      sortType: 'alphanumericFalsyLast',
    },
    {
      Header: 'XCJ Clear',
      accessor: 'attributes.xcjClearPercentage',
      sortType: 'alphanumericFalsyLast',
      Cell: (props) => <div>{props.value}%</div>,
    },
    {
      Header: 'XCT Clear',
      accessor: 'attributes.xctClearPercentage',
      sortType: 'alphanumericFalsyLast',
      Cell: (props) => <div>{props.value}%</div>,
    },
    {
      Header: 'XCT Avg Pens',
      accessor: 'attributes.xcTimeAvg',
      sortType: 'alphanumericFalsyLast',
    },
    {
      Header: 'SJ Clear',
      accessor: 'attributes.sjClearPercentage',
      sortType: 'alphanumericFalsyLast',
      Cell: (props) => <div>{props.value}%</div>,
    },
    {
      Header: 'SJJ Avg',
      accessor: 'attributes.sjJumpAvg',
      sortType: 'alphanumericFalsyLast',
    },
    {
      Header: 'Avg FS',
      accessor: 'attributes.finalScoreAvg',
      sortType: 'alphanumericFalsyLast',
    },
    {
      Header: 'Avg HPR',
      accessor: 'attributes.hprAvg',
      Cell: (props) => <div>{props.value || '-'}</div>,
      sortType: 'alphanumericFalsyLast',
    },
  ])

  const ratedResultsColumns = useMemo(() => [
    {
      Header: 'Competition',
      textAlign: 'left',
      accessor: 'attributes.competition',
      isBold: true,
      cursor: 'pointer',
      Cell: (props) => <div>{props.value}</div>,
    },
    {
      Header: 'Class',
      accessor: 'attributes.class',
    },
    {
      Header: 'Date',
      accessor: 'attributes.date',
    },
    {
      Header: 'Horse',
      accessor: 'attributes.horse',
      textAlign: 'left',
    },
    {
      Header: 'DR Score',
      sortType: 'alphanumericFalsyLast',
      accessor: 'attributes.drScore',
      Cell: (props) => (
        <div>{props.value || props.value === 0 ? props.value : '-'}</div>
      ),
    },
    {
      Header: 'XC Jump',
      sortType: 'alphanumericFalsyLast',
      accessor: 'attributes.xcJump',
      Cell: (props) => (
        <div>{props.value || props.value === 0 ? props.value : '-'}</div>
      ),
    },
    {
      Header: 'XC Time',
      sortType: 'alphanumericFalsyLast',
      accessor: 'attributes.xcTime',
      Cell: (props) => (
        <div>{props.value || props.value === 0 ? props.value : '-'}</div>
      ),
    },
    {
      Header: 'SJ Jump',
      sortType: 'alphanumericFalsyLast',
      accessor: 'attributes.sjJump',
      Cell: (props) => (
        <div>{props.value || props.value === 0 ? props.value : '-'}</div>
      ),
    },
    {
      Header: 'SJ Time',
      sortType: 'alphanumericFalsyLast',
      accessor: 'attributes.sjTime',
      Cell: (props) => (
        <div>{props.value || props.value === 0 ? props.value : '-'}</div>
      ),
    },
    {
      Header: 'Final Score',
      accessor: 'attributes.finalScore',
      sortType: 'alphanumericFalsyLast',
      Cell: (props) => (
        <div>{props.value || props.value === 0 ? props.value : '-'}</div>
      ),
    },
    {
      Header: 'Position',
      accessor: 'attributes.finalPosition',
      sortType: 'alphanumericFalsyLast',
    },
    {
      Header: 'HPR',
      accessor: 'attributes.hpr',
      Cell: (props) => <div>{props.value}</div>,
      sortType: 'alphanumericFalsyLast',
    },
  ])

  const recentFormAnalysisColumns = useMemo(() => [
    {
      Header: 'Event',
      textAlign: 'left',
      columns: [
        {
          Header: 'Venue',
          textAlign: 'left',
          accessor: 'attributes.venue',
          isBold: true,
          cursor: 'pointer',
          Cell: (props) => (
            <div
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              onClick={() => {
                localStorage.setItem('navTab', 3)
                navigate(`/venues/competition/${props.value}`, {
                  state: {
                    id: props.row.original.attributes.competitionId,
                    name: `${props.value} ${props.row.original.attributes.class}`,
                    date: props.row.original.attributes.competitionDate,
                  },
                })
              }}
            >
              {props.value}
            </div>
          ),
        },
        {
          Header: 'Class',
          accessor: 'attributes.class',
        },
        {
          Header: 'Date',
          accessor: 'attributes.competitionDate',
        },
        {
          Header: 'Horse',
          accessor: 'attributes.horse',
          textAlign: 'left',
          Cell: (props) => (
            <div
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              onClick={() => {
                localStorage.setItem('navTab', 1)
                navigate(`/horses/${props.row.original.attributes.horseId}`, {
                  state: {
                    id: props.row.original.attributes.horseId,
                    name: props.value,
                    athlete: props.row.original.attributes.athlete,
                    elo: props.row.original.attributes.currentElo,
                  },
                })
              }}
            >
              {props.value}
            </div>
          ),
        },
      ],
    },
    {
      Header: 'EquiRatings HP Analysis',
      columns: [
        {
          Header: 'ADS',
          accessor: 'attributes.edrDiffAvg',
          sortType: 'alphanumericFalsyLast',
          Cell: (props) => (
            <div>{props.value || props.value === 0 ? props.value : '-'}</div>
          ),
        },
        {
          Header: 'XJS',
          sortType: 'alphanumericFalsyLast',
          accessor: 'attributes.excjDiffAvg',
          Cell: (props) => (
            <div>{props.value || props.value === 0 ? props.value : '-'}</div>
          ),
        },
        {
          Header: 'XTS',
          sortType: 'alphanumericFalsyLast',
          accessor: 'attributes.exct50DiffAvg',
          Cell: (props) => (
            <div>{props.value || props.value === 0 ? props.value : '-'}</div>
          ),
        },
        {
          Header: 'SJS',
          sortType: 'alphanumericFalsyLast',
          accessor: 'attributes.esjDiffAvg',
          Cell: (props) => (
            <div>{props.value || props.value === 0 ? props.value : '-'}</div>
          ),
        },
        {
          Header: 'Winning HPR',
          sortType: 'alphanumericFalsyLast',
          accessor: 'attributes.winningHpr',
          Cell: (props) => (
            <div>{props.value || props.value === 0 ? props.value : '-'}</div>
          ),
        },
      ],
    },
  ])

  const recentFormColumns = useMemo(() => [
    {
      Header: 'Event',
      textAlign: 'left',
      columns: [
        {
          Header: 'Venue',
          textAlign: 'left',
          accessor: 'attributes.venue',
          isBold: true,
          cursor: 'pointer',
          Cell: (props) => (
            <div
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              onClick={() => {
                localStorage.setItem('navTab', 3)
                navigate(`/venues/competition/${props.value}`, {
                  state: {
                    id: props.row.original.attributes.competitionId,
                    name: `${props.value} ${props.row.original.attributes.class}`,
                    date: props.row.original.attributes.competitionDate,
                  },
                })
              }}
            >
              {props.value}
            </div>
          ),
        },
        {
          Header: 'Class',
          accessor: 'attributes.class',
        },
        {
          Header: 'Date',
          accessor: 'attributes.competitionDate',
        },
        {
          Header: 'Horse',
          accessor: 'attributes.horse',
          textAlign: 'left',
          Cell: (props) => (
            <div
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              onClick={() => {
                localStorage.setItem('navTab', 1)
                navigate(`/horses/${props.row.original.attributes.horseId}`, {
                  state: {
                    id: props.row.original.attributes.horseId,
                    name: props.value,
                    athlete: props.row.original.attributes.athlete,
                    elo: props.row.original.attributes.currentElo,
                  },
                })
              }}
            >
              {props.value}
            </div>
          ),
        },
      ],
    },
    {
      Header: 'Dressage',
      columns: [
        {
          Header: 'Score',
          sortType: 'alphanumericFalsyLast',
          accessor: 'attributes.drScore',
          Cell: (props) => (
            <div>{props.value || props.value === 0 ? props.value : '-'}</div>
          ),
        },
      ],
    },
    {
      Header: 'Cross Country',
      columns: [
        {
          Header: 'Jump',
          sortType: 'alphanumericFalsyLast',
          accessor: 'attributes.xcJump',
          Cell: (props) => (
            <div>{props.value || props.value === 0 ? props.value : '-'}</div>
          ),
        },
        {
          Header: 'Time',
          sortType: 'alphanumericFalsyLast',
          accessor: 'attributes.xcTime',
          Cell: (props) => (
            <div>{props.value || props.value === 0 ? props.value : '-'}</div>
          ),
        },
      ],
    },
    {
      Header: 'Show Jumping',
      columns: [
        {
          Header: 'Jump',
          accessor: 'attributes.sjJump',
          sortType: 'alphanumericFalsyLast',
          Cell: (props) => (
            <div>{props.value || props.value === 0 ? props.value : '-'}</div>
          ),
        },
        {
          Header: 'Time',
          sortType: 'alphanumericFalsyLast',
          accessor: 'attributes.sjTime',
          Cell: (props) => (
            <div>{props.value || props.value === 0 ? props.value : '-'}</div>
          ),
        },
      ],
    },
    {
      Header: 'Total',
      accessor: 'attributes.finalScore',
      sortType: 'alphanumericFalsyLast',
      Cell: (props) => (
        <div>{props.value || props.value === 0 ? props.value : '-'}</div>
      ),
    },
    {
      Header: 'Pos',
      accessor: 'attributes.finalPosition',
      sortType: 'alphanumericFalsyLast',
      Cell: (props) => (
        <div>
          {props.value === null
            ? props.row.original.attributes.finalStatus
            : props.value}
        </div>
      ),
    },
    {
      Header: 'HPR',
      accessor: 'attributes.hpr',
      sortType: 'alphanumericFalsyLast',
      Cell: (props) => <div>{props.value || '-'}</div>,
    },
  ])
  return (
    <Layout>
      <>
        <Text size="x6" weight="semibold">
          {athleteName}
        </Text>
        <Spacer height="x8" />
        <TitleContainer>
          <Text size="x5" weight="semibold">
            Highest Rated Results
          </Text>
          <DropdownContainer width="70%">
          <div
            style={{ width: '25%', display: 'flex', flexDirection: 'column' }}
          >
            <Text size="x4" weight="semibold">
              Select Level
            </Text>
            <Spacer height="x1" />
            <MultiSelectDropdown
              onChange={(level) => addNewClass(level, selectedLevel, setLevel)}
              readOnly={true}
              items={envVars.allClasses}
              itemToString={(level) => level.key}
              selectedItems={selectedLevel}
              initialItem={envVars.majorClasses[0]}
              handleFilter={() => () => true}
              removeItem={(idx) => removeClass(idx, selectedLevel, setLevel)}
              displaySuggestion={(level) => (
                <Text
                  size={theme.fontScale.x2}
                  weight={theme.fontWeight.normal}
                >
                  {level.key}
                </Text>
              )}
            />
          </div>
          <Spacer width="x4" />
            <div
              style={{ display: 'flex', flexDirection: 'column', width: '15%' }}
            >
              <Text size="x4" weight="semibold">
                Year From
              </Text>
              <Spacer height="x1" />
              <Dropdown
                onChange={(year) => setHrrYearFrom(year)}
                readOnly={true}
                items={envVars.years}
                itemToString={(year) => year}
                initialItem={selectYearFromInitial}
                handleFilter={() => () => true}
                displaySuggestion={(year) => (
                  <Text
                    size={theme.fontScale.x2}
                    weight={theme.fontWeight.normal}
                  >
                    {year}
                  </Text>
                )}
              />
            </div>
            <Spacer width="x4" />
            <div
              style={{ display: 'flex', flexDirection: 'column', width: '15%' }}
            >
              <Text size="x4" weight="semibold">
                Year To
              </Text>
              <Spacer height="x1" />
              <Dropdown
                onChange={(year) => setHrrYearTo(year)}
                readOnly={true}
                items={envVars.years}
                itemToString={(year) => year}
                initialItem={selectYearToInitial}
                handleFilter={() => () => true}
                displaySuggestion={(year) => (
                  <Text
                    size={theme.fontScale.x2}
                    weight={theme.fontWeight.normal}
                  >
                    {year}
                  </Text>
                )}
              />
            </div>
          </DropdownContainer>
        </TitleContainer>
        <Spacer height="x2" />
        <Table
          columns={ratedResultsColumns}
          data={ratedResultsData}
          defaultSort={'attributes.hpr'}
        />
        <Spacer height="x8" />
        <TitleContainer>
          <HStack>
            <Text size="x5" weight="semibold">
              Statistical Overview
            </Text>
            <Spacer width="x4" />
            <Question data-tip data-for="stat-overview-athlete" />
          </HStack>
        </TitleContainer>
        <HStack align="center" justify="space-between">
          <div style={{ display: 'flex' }}>
            <Tab active={activeTab === 0} onClick={() => setActiveTab(0)}>
              <Text size="x4" weight="semibold" cursor={'pointer'}>
                Class Overview
              </Text>
            </Tab>
            <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>
              <Text size="x4" weight="semibold" cursor={'pointer'}>
                Recent Form
              </Text>
            </Tab>
            <Tab active={activeTab === 2} onClick={() => setActiveTab(2)}>
              <Text size="x4" weight="semibold" cursor={'pointer'}>
                Field Analysis
              </Text>
            </Tab>
          </div>
          {activeTab === 0 && (
            <DropdownContainer>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text size="x4" weight="semibold">
                  Year From
                </Text>
                <Spacer height="x1" />
                <Dropdown
                  onChange={(year) => setYearFrom(year)}
                  readOnly={true}
                  items={envVars.years}
                  itemToString={(year) => year}
                  initialItem={selectYearFromInitial}
                  handleFilter={() => () => true}
                  displaySuggestion={(year) => (
                    <Text
                      size={theme.fontScale.x2}
                      weight={theme.fontWeight.normal}
                    >
                      {year}
                    </Text>
                  )}
                />
              </div>
              <Spacer width="x4" />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text size="x4" weight="semibold">
                  Year To
                </Text>
                <Spacer height="x1" />
                <Dropdown
                  onChange={(year) => setYearTo(year)}
                  readOnly={true}
                  items={envVars.years}
                  itemToString={(year) => year}
                  initialItem={selectYearToInitial}
                  handleFilter={() => () => true}
                  displaySuggestion={(year) => (
                    <Text
                      size={theme.fontScale.x2}
                      weight={theme.fontWeight.normal}
                    >
                      {year}
                    </Text>
                  )}
                />
              </div>
              <Spacer height="x2" />
            </DropdownContainer>
          )}
        </HStack>
        <Spacer height="x2" />
        {activeTab === 1 ? (
          <Table
            columns={recentFormColumns}
            data={statOverview}
            defaultSort={'attributes.competitionDate'}
          />
        ) : activeTab === 2 ? (
          <Table columns={recentFormAnalysisColumns} data={statOverview} />
        ) : (
          <Table
            columns={classOverviewColumns}
            data={classOverviewData}
            defaultSort={'attributes.hprAvg'}
          />
        )}

        <ReactTooltip
          id="stat-overview-athlete"
          place="right"
          effect="solid"
          multiline={true}
          backgroundColor={theme.colors.primary100}
          textColor={theme.colors.white100}
        >
          {activeTab === 0 ? (
            <Text weight="semibold" size="x2" color="white100">
              <Text size="x2" weight="bold" color="white100">
                Class Overview
              </Text>
              <Spacer height="x3" />
              <HStack>
                You can
                <Spacer width="x1" />
                <Text size="x2" color="secondary100" weight="semibold">
                  sort
                </Text>
                <Spacer width="x1" />
                by any column by clicking on the column header.
              </HStack>
              <Spacer height="x2" />
              SJ Clear includes jumping and time penalties.
              <Spacer height="x2" />
              Avg HPR: Each run gets an EquiRatings High Performance Rating
              (HPR), the closer to 100 the better.
              <Spacer height="x2" />
              We show the average HPR at the given level.
            </Text>
          ) : activeTab === 1 ? (
            <Text weight="semibold" size="x2" color="white100">
              <Text size="x2" weight="bold" color="white100">
                Recent Form
              </Text>
              <Spacer height="x3" />
              <HStack>
                You can
                <Spacer width="x1" />
                <Text size="x2" color="secondary100" weight="semibold">
                  sort
                </Text>
                <Spacer width="x1" />
                by any column by clicking on the column header.
              </HStack>
              <Spacer height="x2" />
              Each run gets an EquiRatings High Performance Rating (HPR), the
              closer to 100 the better.
              <Spacer height="x2" />
              Alongside their score in each phase, we show you the HPR from each
              result.
            </Text>
          ) : (
            <Text weight="semibold" size="x2" color="white100">
              <Text size="x2" weight="bold" color="white100">
                Field Analysis
              </Text>
              <Spacer height="x3" />
              <HStack>
                You can
                <Spacer width="x1" />
                <Text size="x2" color="secondary100" weight="semibold">
                  sort
                </Text>
                <Spacer width="x1" />
                by any column by clicking on the column header.
              </HStack>
              <Spacer height="x2" />
              <HStack>
                <Text size="x2" color="secondary100" weight="semibold">
                  ADS:
                </Text>
                <Spacer width="x1" />
                The difference between actual DR scores and expected DR scores.
                A positive number means actual DR scores were, on average,
                higher than expected DR scores (and vice versa).
              </HStack>
              <Spacer height="x2" />
              <HStack>
                <Text size="x2" color="secondary100" weight="semibold">
                  XJS:
                </Text>
                <Spacer width="x1" />
                The difference between the actual XCJ clear rate and the
                expected XCJ clear rate. A positive number means the actual XCJ
                clear rate was higher than expected (and vice versa).
              </HStack>
              <Spacer height="x2" />
              <HStack>
                <Text size="x2" color="secondary100" weight="semibold">
                  XTS:
                </Text>
                <Spacer width="x1" />
                The difference between the actual XCT penalties and the expected
                XCT penalties (based on the top 50% of the field). A positive
                number means the actual XCT penalties were, on average, higher
                than expected (and vice versa).
              </HStack>
              <Spacer height="x2" />
              <HStack>
                <Text size="x2" color="secondary100" weight="semibold">
                  SJS:
                </Text>
                <Spacer width="x1" />
                The difference between the actual SJJ penalties and the expected
                SJJ penalties. A positive number means the actual SJJ penalties
                were, on average, higher than expected (and vice versa).
              </HStack>
              <Spacer height="x2" />
              <HStack>
                <Text size="x2" color="secondary100" weight="semibold">
                  Winning HPR:
                </Text>
                <Spacer width="x1" />
              </HStack>
              Each run gets an EquiRatings High Performance Rating (HPR), the
              closer to 100 the better.
              <Spacer height="x1" />A Winning HPR is the HPR of a competition’s
              winning performance.
            </Text>
          )}
        </ReactTooltip>
      </>
    </Layout>
  )
}

export default AthleteView
