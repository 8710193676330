import * as React from 'react'

function Question(props) {
  return (
    <svg
      style={{ cursor: 'pointer' }}
      width={24}
      height={24}
      xmlns="http://www.w3.org/2000/svg"
      fill="rgba(26, 56, 82, 1)"
      fillRule="evenodd"
      clipRule="evenodd"
      {...props}
    >
      <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12S0 18.623 0 12 5.377 0 12 0zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11S1 18.071 1 12 5.929 1 12 1zm.053 17a.844.844 0 100-1.689.844.844 0 000 1.689zm.468-2.822h-.998c-.035-1.162.182-2.054.939-2.943.491-.57 1.607-1.479 1.945-2.058C15.129 8.948 14.484 7 12.136 7c-1.439 0-2.615.877-2.928 2.507L8.19 9.405C8.47 7.169 10.148 6 12.112 6c1.964 0 3.615 1.25 3.615 3.22 0 1.806-1.826 2.782-2.638 3.868-.422.563-.555 1.377-.568 2.09z" />
    </svg>
  )
}

export default Question
