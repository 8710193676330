import React, { useEffect, useState } from 'react'
import { navigate } from '@reach/router'
import styled from 'styled-components'
import { theme } from '../config'
import { Layout, Text, Spacer, TextInput } from '../components'
import { Chevron } from '../assets/icons'
import { useResource } from '../hooks'

const InputContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
`

const Athlete = styled.div`
  background: ${theme.colors.white100};
  border-radius: ${theme.radii.x2};
  padding: ${theme.spacing.x6} ${theme.spacing.x4};
  justify-content: space-between;
  align-items: center;
  display: flex;
  box-sizing: border-box;
  margin-bottom: ${theme.spacing.x2};
  flex-direction: row;
  box-shadow: ${theme.boxShadow};
  cursor: pointer;
`

const Athletes = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (${theme.breakpoints.notSmall}) {
    flex-direction: row;
  }
`

const AthletesColumn = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  @media (${theme.breakpoints.notSmall}) {
    width: 49.5%;
  }
`

const IndividualAthlete = ({ athlete }) => {
  return (
    <Athlete
      onClick={() =>
        navigate(`/athletes/${athlete.id}`, {
          state: {
            id: athlete.id,
            name: athlete.attributes.name,
          },
        })
      }
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '70%',
        }}
      >
        <Spacer size={theme.spacing.x3} />
        <Text size="x5" weight="semibold" cursor="pointer">
          {athlete.attributes.name}
        </Text>
      </div>
      <div style={{ display: 'flex', alignSelf: 'center' }}>
        <Chevron direction="left" />
      </div>
    </Athlete>
  )
}

const AthleteSearch = () => {
  const [athleteName, setAthleteName] = useState('')
  const { getAll: getAllAthletes, all: athletes } = useResource(
    'dashboard/high_performance/search?type=athlete'
  )

  useEffect(() => getAllAthletes({ authToken: true }), [])

  const filteredArr =
    athletes &&
    athletes.data.filter((athlete) =>
      athlete.attributes.name.toLowerCase().includes(athleteName.toLowerCase())
    )

  const divideArr = (arr) => {
    let arrLength = arr.length
    let divider = Math.ceil(arrLength / 2)
    let firstArray = arr.slice(0, divider)
    let secondArray = arr.slice(divider, arrLength)
    return {
      firstArray,
      secondArray,
    }
  }
  return (
    <Layout>
      {athletes ? (
        <>
          <InputContainer>
            <Text size="x4" weight="semibold">
              Search Athletes
            </Text>
            <Spacer height="x2" />
            <TextInput
              placeholder="Enter Athlete name here"
              onChange={(e) => setAthleteName(e.target.value)}
            />
          </InputContainer>
          <Spacer height="x8" />
          <Athletes>
            <AthletesColumn>
              {divideArr(filteredArr).firstArray.map((athlete, idx) => {
                return <IndividualAthlete athlete={athlete} />
              })}
            </AthletesColumn>
            <AthletesColumn>
              {divideArr(filteredArr).secondArray.map((athlete, idx) => {
                return <IndividualAthlete athlete={athlete} />
              })}
            </AthletesColumn>
          </Athletes>
        </>
      ) : null}
    </Layout>
  )
}

export default AthleteSearch
