const theme = {
  colors: {
    primary100: 'rgba(26, 56, 82, 1)',
    primary90: 'rgba(26, 56, 82, 0.9)',
    primary80: 'rgba(26, 56, 82, 0.8)',
    primary70: 'rgba(26, 56, 82, 0.7)',
    primary60: 'rgba(26, 56, 82, 0.6)',
    primary50: 'rgba(26, 56, 82, 0.5)',
    primary40: 'rgba(26, 56, 82, 0.4)',
    primary30: 'rgba(26, 56, 82, 0.3)',
    primary20: 'rgba(26, 56, 82, 0.2)',
    primary10: 'rgba(26, 56, 82, 0.1)',

    secondary100: 'rgba(255, 188, 73, 1)',
    secondary90: 'rgba(255, 188, 73, 0.9)',
    secondary80: 'rgba(255, 188, 73, 0.8)',
    secondary70: 'rgba(255, 188, 73, 0.7)',
    secondary60: 'rgba(255, 188, 73, 0.6)',
    secondary50: 'rgba(255, 188, 73, 0.5)',
    secondary40: 'rgba(255, 188, 73, 0.4)',
    secondary30: 'rgba(255, 188, 73, 0.3)',
    secondary20: 'rgba(255, 188, 73, 0.2)',
    secondary10: 'rgba(255, 188, 73, 0.1)',

    green100: 'rgba(99,190,123, 100)',
    green90: 'rgba(99,190,123, 90)',
    green80: 'rgba(99,190,123, 80)',
    green70: 'rgba(99,190,123, 70)',
    green60: 'rgba(99,190,123, 60)',
    green50: 'rgba(99,190,123, 50)',
    green40: 'rgba(99,190,123, 40)',
    green30: 'rgba(99,190,123, 30)',
    green20: 'rgba(99,190,123, 20)',
    green10: 'rgba(99,190,123, 10)',

    red100: 'rgba(248,105,107, 100)',
    red90: 'rgba(248,105,107, 90)',
    red80: 'rgba(248,105,107, 80)',
    red70: 'rgba(248,105,107, 70)',
    red60: 'rgba(248,105,107, 60)',
    red50: 'rgba(248,105,107, 50)',
    red40: 'rgba(248,105,107, 40)',
    red30: 'rgba(248,105,107, 30)',
    red20: 'rgba(248,105,107, 20)',
    red10: 'rgba(248,105,107, 10)',

    white100: 'rgba(255, 255, 255, 1)',
    white90: 'rgba(255, 255, 255, 0.9)',
    white80: 'rgba(255, 255, 255, 0.8)',
    white70: 'rgba(255, 255, 255, 0.7)',
    white60: 'rgba(255, 255, 255, 0.6)',
    white50: 'rgba(255, 255, 255, 0.5)',
    white40: 'rgba(255, 255, 255, 0.4)',
    white30: 'rgba(255, 255, 255, 0.3)',
    white20: 'rgba(255, 255, 255, 0.2)',
    white10: 'rgba(255, 255, 255, 0.1)',
  },

  boxShadow: '0px 8px 24px 0px rgba(149,157,165,0.2)',

  spacing: {
    x0: '0px',
    x1: '4px',
    x2: '8px',
    x3: '12px',
    x4: '16px',
    x5: '20px',
    x6: '24px',
    x7: '28px',
    x8: '32px',
    x9: '36px',
    x10: '40px',
    x11: '44px',
    x12: '48px',
    x13: '52px',
    x14: '56px',
    x15: '60px',
    x16: '64px',
    x32: '128px',
    x64: '256px',
    x128: '512px',
  },

  radii: {
    x1: '4px',
    x2: '8px',
    x3: '12px',
    x4: '16px',
  },

  breakpoints: {
    notSmall: 'min-width: 30em',
    mediumAndUp: 'min-width: 60em',
    medium: 'min-width: 30em and max-width: 60em',
    large: 'min-width: 60em',
  },

  fontScale: {
    x1: '12px',
    x2: '14px',
    x3: '16px',
    x4: '20px',
    x5: '24px',
    x5s: '30px',
    x6: '36px',
    x7: '48px',
    x10: '60px',
  },

  fontWeight: {
    light: 300,
    normal: 400,
    semibold: 600,
    bold: 700,
  },
}

export default theme
