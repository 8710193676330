import React from 'react'
import styled from 'styled-components'
import { navigate } from '@reach/router'
import { theme } from '../config'
import Text from './Text'
import Spacer from './Spacer'
import { Profile } from '../assets/icons'
import Image from '../assets/images/headerlogo.png'
import { getProviderImage } from '../utilities/helperFunctions'
import Dropdown from './Dropdown'
import HStack from './HStack'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: ${theme.spacing.x4};
`

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
`

const Header = ({}) => {
  const handleLogout = async () => {
    await localStorage.removeItem('authToken')
    await localStorage.removeItem('refreshToken')
    await localStorage.removeItem('tokenCreated')
    navigate('/login')
  }
  const nations = [
    'AUS',
    'BEL',
    'BRA',
    'CAN',
    'CHN',
    'FRA',
    'GBR',
    'GER',
    'IRL',
    'ITA',
    'JPN',
    'NED',
    'NZL',
    'POL',
    'SUI',
    'SWE',
    'THA',
    'USA',
  ]
  return (
    <Container>
      <HeaderTitle>
        <img src={Image} />
        <Spacer width="x4" />
        <Text size="x6" weight="semibold">
          High Performance Centre
        </Text>
        <Spacer width="x4" />
        <div style={{ display: 'flex' }}>{getProviderImage()}</div>
      </HeaderTitle>
      <HStack align="center" justify="flex-end">
        {localStorage.getItem('hpNationality') === 'EQR' ? (
          <div style={{ width: '20%' }}>
            <Dropdown
              onChange={(nation) => {
                localStorage.setItem('erNationality', nation)
              }}
              readOnly={true}
              items={nations}
              itemToString={(nation) => nation}
              initialItem={localStorage.getItem('erNationality') || 'IRL'}
              handleFilter={() => () => true}
              displaySuggestion={(nation) => (
                <Text
                  size={theme.fontScale.x2}
                  weight={theme.fontWeight.normal}
                >
                  {nation}
                </Text>
              )}
            />
          </div>
        ) : null}
        <Spacer width="x4" />
        <Text size="x3" color="red100" onClick={handleLogout}>
          Logout
        </Text>
      </HStack>
    </Container>
  )
}

export default Header
