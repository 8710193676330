import React, { useMemo } from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { navigate } from '@reach/router'
import { theme } from '../config'
import Table from './Table'
import HStack from './HStack'
import Question from '../assets/icons/Question'
import Spacer from './Spacer'
import Text from './Text'
import Dropdown from './Dropdown'
import MultiSelectDropdown from './MultiSelectDropdown'
import { formatStat } from '../utilities/helperFunctions'
import envVars from '../utilities/envVars'

const Tab = styled.div`
	padding: ${theme.spacing.x2} ${theme.spacing.x6};
	border-radius: ${({ active }) => (active ? theme.radii.x2 : 'none')};
	background: ${({ active }) => (active ? theme.colors.white100 : 'none')};
	box-shadow: ${({ active }) => (active ? theme.boxShadow : 'none')};
`

const TitleContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
`

const DropdownContainer = styled.div`
	width: 70%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`

const TeamSquadOverview = ({
	data,
	setYearFrom,
	setYearTo,
	setLevel,
	selectedLevels,
	activeTab,
	setActiveTab,
	yearFromDropdownInitialItem,
	yearToDropdownInitialItem,
}) => {
	const removeClass = (index) => {
		const newArr = [...selectedLevels]
		newArr.splice(index, 1)
		setLevel(newArr)
	}

	const addNewClass = (level) => {
		const someArr = selectedLevels.map((lvl) => lvl.value)
		const isDuplicate = someArr.includes(level.value)
		if (!isDuplicate) {
			setLevel([...selectedLevels, level])
		}
	}

	const newData = useMemo(() => data.data, [data])

	const columns = useMemo(
		() => [
			{
				Header: activeTab === 1 ? 'Athlete' : 'Horse',
				textAlign: 'left',
				accessor: 'attributes.name',
				isBold: true,
				cursor: 'pointer',
				Cell: (props) => (
					<div
						style={{
							cursor: 'pointer',
							textDecoration: 'underline',
						}}
						onClick={() => {
							localStorage.setItem('navTab', 1)
							navigate(`/horses/${props.row.original.attributes.groupById}`, {
								state: {
									id: props.row.original.attributes.groupById,
									name: props.value,
									athlete: props.row.original.attributes.currentHpAthlete,
									elo: props.row.original.attributes.currentElo,
								},
							})
						}}
					>
						{props.value}
					</div>
				),
			},
			{
				Header: 'Overview',
				columns: [
					{
						Header: 'Runs',
						accessor: 'attributes.runs',
					},
					{
						Header: 'MER %',
						accessor: 'attributes.merPercentage',
						Cell: (props) => <div>{props.value}%</div>,
					},
				],
			},
			{
				Header: 'Dressage',
				columns: [
					{
						Header: 'Avg',
						accessor: 'attributes.drAverage',
						Cell: (props) => <div>{formatStat(props.value, 1)}</div>,
					},
					{
						Header: 'Best',
						accessor: 'attributes.drBest',
						Cell: (props) => <div>{formatStat(props.value, 1)}</div>,
					},
				],
			},
			{
				Header: 'Show Jumping',
				columns: [
					{
						Header: 'Avg',
						accessor: 'attributes.sjAverage',
						Cell: (props) => <div>{formatStat(props.value, 1)}</div>,
					},
					{
						Header: 'Clear %',
						accessor: 'attributes.sjClearPercentage',
						Cell: (props) => <div>{props.value}%</div>,
					},
				],
			},
			{
				Header: 'XC Jumping',
				columns: [
					{
						Header: 'Clear %',
						accessor: 'attributes.xcjClearPercentage',
						Cell: (props) => <div>{props.value}%</div>,
					},
					{
						Header: 'Comp %',
						accessor: 'attributes.xcCompletionPercentage',
						Cell: (props) => <div>{props.value}%</div>,
					},
				],
			},
			{
				Header: 'XC Time',
				columns: [
					{
						Header: 'Avg',
						accessor: 'attributes.xctAverage',
						Cell: (props) => <div>{formatStat(props.value, 1)}</div>,
					},
					{
						Header: 'Clear %',
						accessor: 'attributes.xctClearPercentage',
						Cell: (props) => <div>{props.value}%</div>,
					},
				],
			},
			{
				Header: 'Finishing Score',
				columns: [
					{
						Header: 'Avg',
						accessor: 'attributes.finalScoreAverage',
						Cell: (props) => <div>{formatStat(props.value, 1)}</div>,
					},
					{
						Header: 'Best',
						accessor: 'attributes.finalScoreBest',
						Cell: (props) => <div>{formatStat(props.value, 1)}</div>,
					},
				],
			},
			{
				Header: 'KPIs',
				columns: [
					{
						Header: 'Two Phase Avg',
						accessor: 'attributes.twoPhaseAverage',
						sortType: 'alphanumericFalsyLast',
						Cell: (props) => <div>{formatStat(props.value, 1)}</div>,
					},
					{
						Header: 'Power Avg',
						sortType: 'alphanumericFalsyLast',
						accessor: 'attributes.powerAverage',
						Cell: (props) => <div>{formatStat(props.value, 1)}</div>,
					},
					{
						Header: 'Max HPR',
						accessor: 'attributes.maxHpr',
						sortType: 'alphanumericFalsyLast',
						Cell: (props) => <div>{props.value || '-'}</div>,
					},
				],
			},
		],
		[activeTab]
	)

	const isAthlete = localStorage.getItem('hpAthleteId')

	return console.log('Here',isAthlete)||(
		<>
			<TitleContainer>
				<HStack align="center">
					<Text size="x5" weight="semibold">
						Squad Overview
					</Text>
					<Spacer width="x4" />
					<Question data-tip data-for="teamOverview" />
				</HStack>
				<DropdownContainer>
					{isAthlete == 'null' && (
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<Text size="x4" weight="semibold">
								Group by
							</Text>
							<Spacer height="x1" />
							<HStack align="center">
								<Tab active={activeTab === 0} onClick={() => setActiveTab(0)}>
									<Text size="x4" weight="semibold" cursor={'pointer'}>
										Horse
									</Text>
								</Tab>
								<Tab
									active={activeTab === 1}
									onClick={() => {
										setActiveTab(1)
									}}
								>
									<Text size="x4" weight="semibold" cursor={'pointer'}>
										Athlete
									</Text>
								</Tab>
							</HStack>
						</div>
					)}
					<Spacer width="x4" />
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<Text size="x4" weight="semibold">
							Year From
						</Text>
						<Spacer height="x1" />
						<Dropdown
							onChange={(year) => setYearFrom(year)}
							readOnly={true}
							items={envVars.years}
							itemToString={(year) => year}
							initialItem={yearFromDropdownInitialItem}
							handleFilter={() => () => true}
							displaySuggestion={(year) => (
								<Text
									size={theme.fontScale.x2}
									weight={theme.fontWeight.normal}
								>
									{year}
								</Text>
							)}
						/>
					</div>
					<Spacer width="x4" />
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<Text size="x4" weight="semibold">
							Year To
						</Text>
						<Spacer height="x1" />
						<Dropdown
							onChange={(year) => setYearTo(year)}
							readOnly={true}
							items={envVars.years}
							itemToString={(year) => year}
							initialItem={yearToDropdownInitialItem}
							handleFilter={() => () => true}
							displaySuggestion={(year) => (
								<Text
									size={theme.fontScale.x2}
									weight={theme.fontWeight.normal}
								>
									{year}
								</Text>
							)}
						/>
					</div>
					<Spacer width="x4" />
					<div
						style={{ width: '70%', display: 'flex', flexDirection: 'column' }}
					>
						<Text size="x4" weight="semibold">
							Select Level
						</Text>
						<Spacer height="x1" />
						<MultiSelectDropdown
							onChange={(level) => addNewClass(level)}
							readOnly={true}
							items={envVars.allClasses}
							itemToString={(level) => level.key}
							selectedItems={selectedLevels}
							initialItem={selectedLevels[0]}
							handleFilter={() => () => true}
							removeItem={(index) => removeClass(index)}
							displaySuggestion={(level) => (
								<Text
									size={theme.fontScale.x2}
									weight={theme.fontWeight.normal}
								>
									{level.key}
								</Text>
							)}
						/>
					</div>
				</DropdownContainer>
			</TitleContainer>
			<Spacer height="x4" />
			<Table
				columns={columns}
				data={newData}
				defaultSort={'attributes.powerAverage'}
				desc={false}
			/>
			<ReactTooltip
				id="teamOverview"
				place="right"
				effect="solid"
				multiline={true}
				backgroundColor={theme.colors.primary100}
				textColor={theme.colors.white100}
			>
				<Text weight="semibold" size="x2" color="white100">
					<HStack>
						You can
						<Spacer width="x1" />
						<Text size="x2" color="secondary100" weight="semibold">
							filter
						</Text>
						<Spacer width="x1" />
						by year and level.
					</HStack>
					<Spacer height="x2" />
					<HStack>
						You can
						<Spacer width="x1" />
						<Text size="x2" color="secondary100" weight="semibold">
							sort
						</Text>
						<Spacer width="x1" />
						by any column by clicking on the column header.
					</HStack>
					<Spacer height="x2" />
					Show Jumping: Both the Avg and Best represent jumping penalties only.
					<Spacer height="x2" />
					Two Phase Avg: average DR score + average SJ score at the selected
					year and levels.
					<Spacer height="x2" />
					Power Avg: average of the three lowest finishing scores at the
					selected year and levels.
				</Text>
			</ReactTooltip>
		</>
	)
}

export default TeamSquadOverview
