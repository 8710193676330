import React from 'react'
import styled from 'styled-components'
import Downshift from 'downshift'
import { theme } from '../config'

import { Chevron } from '../assets/icons'

const Input = styled.input`
  display: flex;
  border-radius: ${theme.spacing.x2};
  background: ${theme.colors.white100};
  box-sizing: border-box;
  border: none;
  box-shadow: ${theme.boxShadow};
  font-size: ${({ fontSize }) => fontSize || theme.fontScale.x4};
  font-weight: ${theme.fontWeight.bold};
  color: ${theme.colors.primary100};
  margin: 0;
  padding: ${({ padding }) => (padding ? padding : theme.spacing.x3)};
  outline: none;
  width: 100%;
`

const Relative = styled.div`
  background: ${theme.colors.white100};
  position: relative;
  & > svg,
  & > button {
    position: absolute;
    right: ${theme.spacing.x4};
    top: 40%;
    transform: translateY(-50%);
    -webkit-appearance: none;
    border: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
    & > svg {
      position: absolute;
      top: 1px;
      transform: ${({ isOpen }) => (isOpen ? `rotate(180deg)` : ``)};
    }
  }
`

const Suggestions = styled.div`
  border-radius: 0 4px;
  box-shadow: ${theme.boxShadow};
  background: ${theme.colors.white100};
  border-radius: ${theme.spacing.x2};
  border: 0.8px solid ${theme.colors.primary30};
  width: 100%;
  margin-top: 0.8%;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
`

const Suggestion = styled.div`
  background: ${theme.colors.white100};
  padding: ${theme.spacing.x2} ${theme.spacing.x3};
  border-left: 2px solid
    ${({ highlighted }) =>
      highlighted ? theme.colors.secondary100 : theme.colors.white100};
`

const Dropdown = ({
  onChange,
  items,
  itemToString,
  placeholder,
  handleFilter,
  displaySuggestion,
  initialItem,
  readOnly,
  fontSize,
  padding,
}) => (
  <Downshift
    onChange={onChange}
    itemToString={(item) => (item ? itemToString(item) : '')}
    initialSelectedItem={initialItem}
  >
    {({
      getRootProps,
      getInputProps,
      getItemProps,
      isOpen,
      highlightedIndex,
      toggleMenu,
      inputValue,
      selectedItem,
      initialSelectedItem,
    }) => (
      <div>
        <Relative isOpen={isOpen}>
          <div style={{ width: '100%' }}>
            <Input
              type="text"
              fontSize={fontSize}
              placeholder={placeholder}
              padding={padding}
              onClick={toggleMenu}
              readOnly={readOnly}
              {...getInputProps()}
            />
            {isOpen && (
              <Suggestions>
                {items.filter(handleFilter(inputValue)).map((item, idx) => (
                  <Suggestion
                    {...getItemProps({
                      item,
                      key: idx,
                      highlighted: highlightedIndex === idx,
                      selected: selectedItem === item,
                    })}
                  >
                    {displaySuggestion(item)}
                  </Suggestion>
                ))}
              </Suggestions>
            )}
          </div>
          <button onClick={toggleMenu} style={{ marginRight: '20px' }}>
            <Chevron isOpen={isOpen} />
          </button>
        </Relative>
      </div>
    )}
  </Downshift>
)

export default Dropdown
