import React, { Children } from 'react'
import { theme } from '../config'
import styled from 'styled-components'
import Header from './Header'
import Spacer from './Spacer'
import NavigationBar from './NavigationBar'
import { useLocation } from '@reach/router'

const Container = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  height: 100%;
  padding: ${theme.spacing.x2} ${theme.spacing.x4};
`
const Layout = ({ children }) => {
  return (
    console.log(window.location.pathname) || (
      <Container>
        {window.location.pathname !== '/login' && (
          <>
            <Header />
            <Spacer height="x8" />
            <NavigationBar />
            <Spacer height="x8" />
          </>
        )}
        {children}
        <Spacer height="x8" />
      </Container>
    )
  )
}

export default Layout
