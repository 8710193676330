import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useTable, useSortBy } from 'react-table'
import { theme } from '../config'
import Text from './Text'
import Spacer from './Spacer'
import Dropdown from './Dropdown'

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${theme.colors.white100};
  border-radius: ${theme.radii.x4};
  overflow: auto;
  box-shadow: ${theme.boxShadow};
`

const TableContainer = styled.table`
  border-collapse: collapse;
`

const cellStyle = css`
  padding: ${theme.spacing.x5} ${theme.spacing.x3};
  color: ${theme.colors.primary100};
  line-height: 1.25;
`

const Th = styled.th`
  ${cellStyle}
  background: ${({ bg }) => bg || 'none'};
  font-size: ${theme.fontScale.x3};
  font-weight: ${theme.fontWeight.bold};
  text-align: ${({ textAlign }) => textAlign || 'right'};
  border-bottom: 0.5px solid rgba(26, 56, 82, 0.2);
  border-right: 0.5px solid rgba(26, 56, 82, 0.2);
  :last-child {
    border-right: none;
  }
`
const Tr = styled.tr`
  :hover {
    background: ${({ isHeader }) =>
      isHeader ? 'none' : theme.colors.primary10};
  }
`
const Td = styled.td`
  ${cellStyle}
  position: relative;
  font-weight: ${({ isBold }) =>
    isBold ? theme.fontWeight.bold : theme.fontWeight.normal};
  font-size: ${theme.fontScale.x3};
  text-align: ${({ textAlign }) => textAlign || 'right'};
  cursor: ${({ cursor }) => cursor || 'auto'};
  border-right: 0.5px solid rgba(26, 56, 82, 0.2);
  :last-child {
    border-right: none;
  }
  :hover {
    background: ${theme.colors.secondary40};
  }
`

const BarOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: ${({ bg }) => bg || 'none'};
  width: ${({ barWidth }) => barWidth || 0};
  opacity: 0.5;
`

const SeeMoreContainer = styled.div`
  border-top: 0.5px solid rgba(26, 56, 82, 0.2);
  padding-top: ${theme.spacing.x4};
  padding-bottom: ${theme.spacing.x4};
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const Table = ({
  title,
  columns,
  data,
  showColumnDistribution,
  defaultSort,
  showSeeMore = true,
  desc = true,
}) => {
  const sortTypes = React.useMemo(
    () => ({
      alphanumericFalsyLast(rowA, rowB, columnId, desc) {
        if (!rowA.values[columnId] && !rowB.values[columnId]) {
          return 0
        }

        if (!rowA.values[columnId]) {
          return desc ? -1 : 1
        }

        if (!rowB.values[columnId]) {
          return desc ? 1 : -1
        }

        return rowA.values[columnId] === rowB.values[columnId]
          ? 0
          : parseFloat(rowA.values[columnId]) >
            parseFloat(rowB.values[columnId])
          ? 1
          : -1
      },
    }),
    []
  )

  const [sliceTo, setSliceTo] = useState(5)
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          sortBy: [{ id: defaultSort, desc: desc }],
        },
        sortTypes,
      },
      useSortBy
    )

  return (
    <>
      <OuterContainer>
        <TableContainer {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()} isHeader={true}>
                {headerGroup.headers.map((column) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    textAlign={column.textAlign}
                    bg={column.bg}
                  >
                    {column.render('Header')}
                    <span
                      style={{
                        marginLeft: theme.spacing.x1,
                        fontSize: theme.fontScale.x2,
                      }}
                    >
                      {column.isSorted ? (column.isSortedDesc ? '▼' : '▲') : ''}
                    </span>
                  </Th>
                ))}
              </Tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.slice(0, sliceTo).map((row) => {
              prepareRow(row)
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Td
                        {...cell.getCellProps()}
                        textAlign={cell.column.textAlign}
                        cursor={cell.column.cursor}
                        isBold={cell.column.isBold}
                      >
                        {showColumnDistribution && (
                          <BarOverlay
                            barWidth={`${
                              (cell.value / row.values[cell.column.total]) * 100
                            }%`}
                            bg={cell.column.bg}
                          />
                        )}
                        {cell.render('Cell')}
                      </Td>
                    )
                  })}
                </Tr>
              )
            })}
          </tbody>
        </TableContainer>
        {showSeeMore &&
          (sliceTo < data.length ? (
            <SeeMoreContainer>
              <Text
                size="x5"
                weight="semibold"
                cursor="pointer"
                onClick={() => setSliceTo(data.length)}
              >
                See More
              </Text>
            </SeeMoreContainer>
          ) : (
            <SeeMoreContainer>
              <Text
                size="x5"
                weight="semibold"
                cursor="pointer"
                onClick={() => setSliceTo(5)}
              >
                See Less
              </Text>
            </SeeMoreContainer>
          ))}
      </OuterContainer>
    </>
  )
}

export default Table
