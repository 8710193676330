// import { useState, useCallback, useEffect } from 'react'
// import http from '../utilities/http'
// import cookies from 'universal-cookie'

// function useAuth() {
//   const [user, setUser] = useState()
//   const [error, setError] = useState()

//   useEffect(() => {
//     if (!error && user) {
//       localStorage.setItem('authToken', user.data.attributes.accessToken)
//       localStorage.setItem('refreshToken', user.data.attributes.refreshToken)
//       localStorage.setItem('tokenCreated', Date.now())
//     }
//   }, [user])

//   function handleError(e) {
//     console.log(e)
//     setError(e)
//   }

//   const loginUser = useCallback(async function (email, password) {
//     await http.post(
//       'https://eventing.api.equiratings.com/v1/sessions',
//       {
//         email: email,
//         password: password,
//       },
//       setUser,
//       handleError,
//       {},
//       () => console.log(true)
//     )
//   }, [])
//   return {
//     loginUser,
//     user,
//     error,
//   }
// }

// export default useAuth

import axios from 'axios'
const instance = () => {
  const API_HOST_URL = 'https://eventing.api.equiratings.com/v1'
  const options = {
    baseURL: `${API_HOST_URL}`,
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const client = axios.create(options)
  return client
}

export const loginUser = (email, password) => {
  return instance().post('/sessions', {
    email: email,
    password: password,
  })
}
