import React, { useEffect, useMemo, useState } from 'react'
import humps, { camelizeKeys } from 'humps'
import styled from 'styled-components'
import * as d3 from 'd3'
import {
  HStack,
  LineChart,
  Dropdown,
  VStack,
  Text,
  Spacer,
  Table,
  Layout,
} from '../components'

import { useResource } from '../hooks'
import { theme } from '../config'
import { parseWithOptions } from 'date-fns/fp'
import { navigate } from '@reach/router'

const ExpectedBox = styled.div`
  width: 165px;
  height: 165px;
  display: flex;
  align-items: center;
  background: ${theme.colors.white100};
  border-radius: ${theme.radii.x4};
  box-shadow: ${theme.boxShadow};
`

const SimpleMetricsBox = styled.div`
  width: 25%;
  height: 490px;
  background: ${theme.colors.white100};
  border-radius: ${theme.radii.x4};
  box-shadow: ${theme.boxShadow};
`

const ExpectedsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const DropdownContainer = styled.div`
  width: 40%;
  display: flex;
`
const Tab = styled.div`
  margin-right: ${theme.spacing.x4};
  margin-top: ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x2};
  padding: ${theme.spacing.x2} ${theme.spacing.x6};
  border-radius: ${({ active }) => (active ? theme.radii.x2 : 'none')};
  background: ${({ active }) => (active ? theme.colors.white100 : 'none')};
  box-shadow: ${({ active }) => (active ? theme.boxShadow : 'none')};
`

const CompetitionView = (props, {}) => {
  const competitionId = props.location.state.id
  const competitionName = props.location.state.name
  const competitionDate = props.location.state.date

  const [phase, setPhase] = useState('Dressage')

  const { all: competitionSummary, getAll: getCompetitionSummary } =
    useResource(
      `dashboard/high_performance/competitions_analysis
		?year_from=2000
		&year_to=2040
    &group_by=competition
		&competition_id=${competitionId}&
    decimal_places=1&`
    )

  const { all: competitionResults, getAll: getCompetitionResults } =
    useResource(
      `dashboard/high_performance/competition_results
		?competition_id=${competitionId}&decimal_places=1&`
    )

  useEffect(() => {
    getCompetitionSummary({ authToken: true })
    getCompetitionResults({ authToken: true })
  }, [])

  const compResults = React.useMemo(
    () => (competitionResults && competitionResults.data) || []
  )
  const resultsColumns = React.useMemo(() => [
    {
      Header: 'Athlete & Horse',
      textAlign: 'left',
      columns: [
        {
          Header: 'Athlete',
          textAlign: 'left',
          accessor: 'attributes.athlete',
          isBold: true,
          cursor: 'pointer',
          Cell: (props) => (
            <div
              style={{
                cursor: 'pointer',
                textDecoration:
                  props.row.original.attributes.athleteNationality ==
                  localStorage.getItem('hpNationality')
                    ? 'underline'
                    : 'none',
              }}
              onClick={() => {
                if (
                  props.row.original.attributes.athleteNationality ==
                  localStorage.getItem('hpNationality')
                ) {
                  localStorage.setItem('navTab', 2)
                  navigate(
                    `/athletes/${props.row.original.attributes.athleteId}`,
                    {
                      state: {
                        id: props.row.original.attributes.athleteId,
                        name: props.row.original.attributes.athlete,
                      },
                    }
                  )
                }
              }}
            >
              {props.value}
            </div>
          ),
        },
        {
          Header: 'Nation',
          accessor: 'attributes.athleteNationality',
          Cell: (props) => (
            <Text
              color={
                props.value == localStorage.getItem('hpNationality')
                  ? 'green100'
                  : 'primary100'
              }
              weight={
                props.value == localStorage.getItem('hpNationality')
                  ? 'bold'
                  : 'normal'
              }
            >
              {props.value}
            </Text>
          ),
        },
        {
          Header: 'Horse',
          accessor: 'attributes.horse',
          Cell: (props) => (
            <div
              style={{
                cursor: 'pointer',
                textDecoration:
                  props.row.original.attributes.athleteNationality ==
                  localStorage.getItem('hpNationality')
                    ? 'underline'
                    : 'none',
              }}
              onClick={() => {
                if (
                  props.row.original.attributes.athleteNationality ==
                  localStorage.getItem('hpNationality')
                ) {
                  localStorage.setItem('navTab', 1)
                  navigate(`/horses/${props.row.original.attributes.horseId}`, {
                    state: {
                      id: props.row.original.attributes.horseId,
                      name: props.value,
                      athlete: props.row.original.attributes.athlete,
                      elo: props.row.original.attributes.currentElo,
                    },
                  })
                }
              }}
            >
              {props.value}
            </div>
          ),
        },
      ],
    },
    {
      Header: 'Dressage',
      columns: [
        {
          Header: 'Score',
          accessor: 'attributes.drScore',
          Cell: (props) => (
            <div>{props.value || props.value == 0 ? props.value : '-'}</div>
          ),
        },
      ],
    },
    {
      Header: 'Cross Country',
      columns: [
        {
          Header: 'Jump',
          accessor: 'attributes.xcJump',
          Cell: (props) => (
            <div>{props.value || props.value == 0 ? props.value : '-'}</div>
          ),
        },
        {
          Header: 'Time',
          accessor: 'attributes.xcTime',
          Cell: (props) => (
            <div>{props.value || props.value == 0 ? props.value : '-'}</div>
          ),
        },
      ],
    },
    {
      Header: 'Show Jumping',
      columns: [
        {
          Header: 'Jump',
          accessor: 'attributes.sjJump',
          Cell: (props) => (
            <div>{props.value || props.value == 0 ? props.value : '-'}</div>
          ),
        },
        {
          Header: 'Time',
          accessor: 'attributes.sjTime',
          Cell: (props) => (
            <div>{props.value || props.value == 0 ? props.value : '-'}</div>
          ),
        },
      ],
    },
    {
      Header: 'Total',
      accessor: 'attributes.finalScore',
      Cell: (props) => (
        <div>{props.value || props.value == 0 ? props.value : '-'}</div>
      ),
    },
    {
      Header: 'Position',
      accessor: 'attributes.finalPosition',
      sortType: 'alphanumericFalsyLast',
      Cell: (props) => (
        <div>{props.value || props.value == 0 ? props.value : '-'}</div>
      ),
    },
    {
      Header: 'HPR',
      accessor: 'attributes.hpr',
      Cell: (props) => (
        <div>{props.value || props.value == 0 ? props.value : '-'}</div>
      ),
    },
  ])
  return (
    <Layout>
      <>
        <Text size="x6" weight="semibold">
          {competitionName}
        </Text>
        <Spacer height="x2" />
        <Text size="x4" weight="normal" color="primary50">
          {competitionDate}
        </Text>
        <Spacer height="x4" />
        {competitionSummary ? (
          <HStack align="baseline" justify="space-between">
            <VStack>
              <TitleContainer>
                <Text size="x5" weight="semibold">
                  Competition Summary
                </Text>
              </TitleContainer>
              <Spacer height="x4" />
              <ExpectedsContainer>
                <ExpectedBox>
                  <VStack align="Center">
                    <Text size="x6" weight="semibold">
                      {competitionSummary.data[0].attributes.starterCountAvg}
                    </Text>
                    <Spacer height="x2" />
                    <Text size="x4" weight="normal" color="primary50">
                      Starters
                    </Text>
                  </VStack>
                </ExpectedBox>
                <Spacer width="x4" />
                <ExpectedBox>
                  <VStack align="Center">
                    <Text size="x6" weight="semibold">
                      {competitionSummary.data[0].attributes.fieldStrengthAvg}
                    </Text>
                    <Spacer height="x2" />
                    <Text size="x4" weight="normal" color="primary50">
                      Field Strength
                    </Text>
                  </VStack>
                </ExpectedBox>
                <Spacer width="x4" />
                <ExpectedBox>
                  <VStack align="Center">
                    <Text size="x6" weight="semibold">
                      {
                        competitionSummary.data[0].attributes
                          .completionPercentage
                      }
                      %
                    </Text>
                    <Spacer height="x2" />
                    <Text size="x4" weight="normal" color="primary50">
                      Comp Rate
                    </Text>
                  </VStack>
                </ExpectedBox>
                <Spacer width="x4" />
                <ExpectedBox>
                  <VStack align="Center">
                    <Text size="x6" weight="semibold">
                      {competitionSummary.data[0].attributes.merPercentage}%
                    </Text>
                    <Spacer height="x2" />
                    <Text size="x4" weight="normal" color="primary50">
                      MER Rate
                    </Text>
                  </VStack>
                </ExpectedBox>
              </ExpectedsContainer>
            </VStack>
            <Spacer width="x8" />
            <VStack>
              <TitleContainer>
                <Text size="x5" weight="semibold">
                  Phase Summary
                </Text>
                <DropdownContainer>
                  <VStack>
                    <Text size="x4" weight="semibold">
                      Select Level
                    </Text>
                    <Spacer height="x1" />
                    <Dropdown
                      onChange={(phase) => setPhase(phase)}
                      readOnly={true}
                      items={['Dressage', 'Show Jumping', 'XC Jump', 'XC Time']}
                      itemToString={(level) => level}
                      initialItem={['Dressage']}
                      handleFilter={() => () => true}
                      displaySuggestion={(phase) => (
                        <Text
                          size={theme.fontScale.x2}
                          weight={theme.fontWeight.normal}
                        >
                          {phase}
                        </Text>
                      )}
                    />
                  </VStack>
                </DropdownContainer>
              </TitleContainer>
              <Spacer height="x4" />
              <ExpectedsContainer>
                {phase == 'Dressage' ? (
                  <>
                    <ExpectedBox>
                      <VStack align="Center">
                        <Text size="x6" weight="semibold">
                          {competitionSummary.data[0].attributes.drLeader}
                        </Text>
                        <Spacer height="x2" />
                        <Text size="x4" weight="normal" color="primary50">
                          DR Leader
                        </Text>
                      </VStack>
                    </ExpectedBox>
                    <Spacer width="x4" />
                    <ExpectedBox>
                      <VStack align="Center">
                        <Text size="x6" weight="semibold">
                          {competitionSummary.data[0].attributes.drAvg}
                        </Text>
                        <Spacer height="x2" />
                        <Text size="x4" weight="normal" color="primary50">
                          DR Average
                        </Text>
                      </VStack>
                    </ExpectedBox>
                    <Spacer width="x4" />
                    <ExpectedBox>
                      <VStack align="Center">
                        <Text size="x6" weight="semibold">
                          {competitionSummary.data[0].attributes.edrDiffAvg}
                        </Text>
                        <Spacer height="x2" />
                        <Text size="x4" weight="normal" color="primary50">
                          DR Adjustment
                        </Text>
                      </VStack>
                    </ExpectedBox>
                  </>
                ) : phase == 'Show Jumping' ? (
                  <>
                    <ExpectedBox>
                      <VStack align="Center">
                        <Text size="x6" weight="semibold">
                          {
                            competitionSummary.data[0].attributes
                              .sjClearPercentage
                          }
                          %
                        </Text>
                        <Spacer height="x2" />
                        <Text size="x4" weight="normal" color="primary50">
                          SJ Clear Rate
                        </Text>
                      </VStack>
                    </ExpectedBox>
                    <Spacer width="x4" />
                    <ExpectedBox>
                      <VStack align="Center">
                        <Text size="x6" weight="semibold">
                          {competitionSummary.data[0].attributes.sjJumpAvg}
                        </Text>
                        <Spacer height="x2" />
                        <Text size="x4" weight="normal" color="primary50">
                          SJ Jump Avg
                        </Text>
                      </VStack>
                    </ExpectedBox>
                    <Spacer width="x4" />
                    <ExpectedBox>
                      <VStack align="Center">
                        <Text size="x6" weight="semibold">
                          {competitionSummary.data[0].attributes.esjDiffAvg}
                        </Text>
                        <Spacer height="x2" />
                        <Text size="x4" weight="normal" color="primary50">
                          SJ Adjustment
                        </Text>
                      </VStack>
                    </ExpectedBox>
                  </>
                ) : phase == 'XC Jump' ? (
                  <>
                    <ExpectedBox>
                      <VStack align="Center">
                        <Text size="x6" weight="semibold">
                          {
                            competitionSummary.data[0].attributes
                              .xcjClearPercentage
                          }
                          %
                        </Text>
                        <Spacer height="x2" />
                        <Text size="x4" weight="normal" color="primary50">
                          XCJ Clear Rate
                        </Text>
                      </VStack>
                    </ExpectedBox>
                    <Spacer width="x4" />
                    <ExpectedBox>
                      <VStack align="Center">
                        <Text size="x6" weight="semibold">
                          {
                            competitionSummary.data[0].attributes
                              .xcCompletionPercentage
                          }
                          %
                        </Text>
                        <Spacer height="x2" />
                        <Text size="x4" weight="normal" color="primary50">
                          XC Comp Rate
                        </Text>
                      </VStack>
                    </ExpectedBox>
                    <Spacer width="x4" />
                    <ExpectedBox>
                      <VStack align="Center">
                        <Text size="x6" weight="semibold">
                          {competitionSummary.data[0].attributes.excjDiffAvg}
                        </Text>
                        <Spacer height="x2" />
                        <Text size="x4" weight="normal" color="primary50">
                          XCJ Adjustment
                        </Text>
                      </VStack>
                    </ExpectedBox>
                  </>
                ) : phase == 'XC Time' ? (
                  <>
                    <ExpectedBox>
                      <VStack align="Center">
                        <Text size="x6" weight="semibold">
                          {
                            competitionSummary.data[0].attributes
                              .xctClearPercentage
                          }
                          %
                        </Text>
                        <Spacer height="x2" />
                        <Text size="x4" weight="normal" color="primary50">
                          XCT Clear Rate
                        </Text>
                      </VStack>
                    </ExpectedBox>
                    <Spacer width="x4" />
                    <ExpectedBox>
                      <VStack align="Center">
                        <Text size="x6" weight="semibold">
                          {competitionSummary.data[0].attributes.xcTimeAvg}
                        </Text>
                        <Spacer height="x2" />
                        <Text
                          align="center"
                          size="x4"
                          weight="normal"
                          color="primary50"
                        >
                          XCT Avg
                        </Text>
                      </VStack>
                    </ExpectedBox>
                    <Spacer width="x4" />
                    <ExpectedBox>
                      <VStack align="Center">
                        <Text size="x6" weight="semibold">
                          {competitionSummary.data[0].attributes.exct50DiffAvg}
                        </Text>
                        <Spacer height="x2" />
                        <Text size="x4" weight="normal" color="primary50">
                          XCT Adjustment
                        </Text>
                      </VStack>
                    </ExpectedBox>
                  </>
                ) : null}
              </ExpectedsContainer>
            </VStack>
          </HStack>
        ) : null}
        <Spacer height="x8" />
        <Text size="x5" weight="semibold">
          Competition Results
        </Text>
        <Spacer height="x4" />
        <Table
          columns={resultsColumns}
          data={compResults}
          defaultSort={'attributes.finalPosition'}
          desc={false}
        />
      </>
    </Layout>
  )
}

export default CompetitionView
