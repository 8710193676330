import React from 'react'
const directionToAngle = (direction) => {
  switch (direction) {
    case 'right':
      return 90
    case 'bottom':
      return 180
    case 'left':
      return 270
    default:
      return 0
  }
}

const Chevron = ({ direction }) => (
  <svg
    width="20"
    height="13"
    cursor="pointer"
    viewBox="0 0 39 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    transform={`rotate(${directionToAngle(direction)} 0 0)`}
  >
    <path
      d="M1.96875 2.16669L19.4688 19.6667L36.9688 2.16669"
      stroke="#1A3852"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
)

export default Chevron
