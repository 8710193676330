import React, { useEffect } from 'react'
import styled from 'styled-components'
import * as d3 from 'd3'
import ReactTooltip from 'react-tooltip'

import { theme } from '../config'
import HStack from './HStack'
import Text from './Text'
import Spacer from './Spacer'
import Question from '../assets/icons/Question'

const Container = styled.div`
  width: 100%;
  background: ${theme.colors.white100};
  border-radius: ${theme.radii.x4};
  box-shadow: ${theme.boxShadow};
`

const LineChart = ({
  title,
  data,
  width,
  height,
  showLegend = true,
  xAxisFormat,
  subTitleLabel1 = '',
  subTitleLabel2 = '',
  showAnnotation = false,
  yMaxVal = false,
  tooltipId,
  tooltipText,
}) => {
  function drawChart() {
    d3.select('#graph').select('svg').remove()
    d3.select('#graph').select('.tooltip').remove()

    const margin = { top: 25, right: 50, bottom: 25, left: 50 }
    const yMinValue = d3.min(data.flat(), (d) => d.value)
    const yMaxValue = d3.max(data.flat(), (d) => d.value)
    const xMinValue = d3.min(data.flat(), (d) => d.label)
    const xMaxValue = d3.max(data.flat(), (d) => d.label)

    const svg = d3
      .select('#graph')
      .append('svg')
      .attr('viewBox', '0 0 1000 375')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('display', 'inline-block')
      .attr('position', 'absolute')
      .attr('top', '0')
      .attr('left', '0')
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)

    const xScale = d3
      .scaleTime()
      .domain([xMinValue, xMaxValue])
      .range([0, width])

    const yScale = d3
      .scaleLinear()
      .domain([
        yMinValue - 10,
        yMaxValue > 800 ? yMaxValue : yMaxVal ? yMaxVal : yMaxValue,
      ])
      .range([height, 0])

    const line = d3
      .line()
      .x((d) => xScale(d.label))
      .y((d) => yScale(d.value))
    var legend = svg
      .append('g')
      .attr('class', 'legend')
      .attr('height', 100)
      .attr('width', 100)
      .attr('position', 'absolute')
      .attr('top', -10)
      .attr('left', -10)
    // .attr('transform', `translate(-${width - 75}, 10)`)

    svg
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0,${height})`)
      .attr('opacity', 0.2)
      .call(
        d3
          .axisBottom()
          .scale(xScale)
          .tickSize(15)
          .tickFormat(d3.timeFormat(xAxisFormat))
      )
    if (showAnnotation) {
      const winningScore = 756
      // svg
      //   .append('line')
      //   .attr('x1', 10)
      //   .attr('x2', 930)
      //   .attr('y1', 75)
      //   .attr('y2', 75)
      //   .style('stroke', 'red')
      //   .style('stroke-dasharray', '3, 3') // <== This line here!!
      //   .style('stroke-width', 2)
      //   .style('opacity', 0.1)
      svg
        .append('text')
        .attr('x', 10)
        .attr('y', 70)
        .style('fill', 'red')
        .style('opacity', 0.3)
        .style('font-size', theme.fontScale.x2)
        .style('font-weight', theme.fontWeight.semibold)
        .text('Average OG and WEG Winning Elo Score: 756')

      // svg
      //   .append('line')
      //   .attr('x1', 10)
      //   .attr('x2', 930)
      //   .attr('y1', 245)
      //   .attr('y2', 245)
      //   .style('stroke', 'blue')
      //   .style('stroke-dasharray', '3, 3') // <== This line here!!
      //   .style('stroke-width', 2)
      //   .style('opacity', 0.1)

      svg
        .append('text')
        .attr('x', 630)
        .attr('y', 280)
        .style('fill', 'blue')
        .style('opacity', 0.3)
        .style('font-size', theme.fontScale.x2)
        .style('font-weight', theme.fontWeight.semibold)
        .text('Average OG and WEG Top 20 Elo Score: 615')
    }

    svg
      .append('g')
      .attr('class', 'y-axis')
      .attr('opacity', 0.2)
      .call(d3.axisLeft(yScale))

    var div = d3
      .select('#graph')
      .append('div')
      .style('opacity', 0)
      .style('background', theme.colors.white100)
      .style('border-radius', theme.radii.x4)
      .style('box-shadow', theme.boxShadow)
      .style('padding', theme.spacing.x4)
      .style('display', 'flex')
      .style('justify-content', 'center')
      .style('width', '100px')
      .style('align-items', 'center')
      .style('color', theme.colors.primary100)
      .style('font-size', theme.fontScale.x3)
      .style('text-align', 'center')

    // const type = d3.annotationLabel
    // const makeAnnotations = d3
    //   .annotation()
    //   .editMode(true)
    //   //also can set and override in the note.padding property
    //   //of the annotation object
    //   .notePadding(15)
    //   .type(type)
    //   .annotations(annotations)

    // svg.append('g').call(makeAnnotations)

    data.forEach((item, idx) => {
      svg
        .append('path')
        .datum(item)
        .attr('fill', 'none')
        .attr(
          'stroke',
          theme.colors[
            idx == 0 ? 'secondary60' : idx == 1 ? 'primary60' : 'green60'
          ]
        )
        .attr('stroke-width', 2)
        .attr('class', 'line')
        .attr('d', line)
      svg
        .selectAll('myCircles')
        .data(item)
        .enter()
        .append('circle')
        .attr('id', 'circleBasicTooltip')
        .attr(
          'fill',
          theme.colors[
            idx == 0 ? 'secondary100' : idx == 1 ? 'primary100' : 'green100'
          ]
        )
        .attr('stroke', 'none')
        .attr('cx', function (d) {
          return xScale(d.label)
        })
        .attr('cy', function (d) {
          return yScale(d.value)
        })
        .attr('r', 4)
        .on('mouseover', function (event, d) {
          div.transition().duration(200).style('opacity', 0.9)
          div
            .html(
              `${subTitleLabel1}: ` +
                d.value +
                '<br/>' +
                `${subTitleLabel2}: ` +
                d.subTitle
            )
            .style('position', 'absolute')
            .style('left', event.pageX + 10 + 'px')
            .style('top', event.pageY - 40 + 'px')
        })
        .on('mouseout', function (d) {
          div.transition().duration(500).style('opacity', 0)
        })
      if (showLegend) {
        svg
          .append('text')
          .attr('x', 680)
          .attr('y', 10)
          .style('fill', theme.colors.secondary50)
          .style('opacity', 0.5)
          .style('font-size', theme.fontScale.x2)
          .style('font-weight', theme.fontWeight.semibold)
          .text('My Top 3')
        svg
          .append('text')
          .attr('x', 760)
          .attr('y', 10)
          .style('fill', theme.colors.primary50)
          .style('opacity', 0.5)
          .style('font-size', theme.fontScale.x2)
          .style('font-weight', theme.fontWeight.semibold)
          .text('My Top 6')
        svg
          .append('text')
          .attr('x', 840)
          .attr('y', 10)
          .style('fill', theme.colors.green50)
          .style('opacity', 0.5)
          .style('font-size', theme.fontScale.x2)
          .style('font-weight', theme.fontWeight.semibold)
          .text('My Top 10')
        //   legend
        //     .selectAll('text')
        //     .data(data)
        //     .enter()
        //     .append('text')
        //     .attr('x', width - 50)
        //     .attr('y', function (d, i) {
        //       return i * 20 + 10
        //     })
        //     .style('fill', function (d, i) {
        //       return theme
        //         .colors[i == 0 ? 'secondary100' : i == 1 ? 'primary100' : 'green100']
        //     })
        //     .style('font-size', theme.fontScale.x1)
        //     .style('font-weight', theme.fontWeight.semibold)
        //     .text(function (d, i) {
        //       if (i == 0) return 'Top 3'
        //       if (i == 1) return 'Top 6'
        //       if (i == 2) return 'Top 10'
        //     })
      }
    })
  }

  useEffect(() => {
    drawChart()
  }, [])

  return (
    <>
      <HStack align="center">
        <Text size="x5" weight="semibold">
          {title}
        </Text>
        <Spacer width="x4" />
        <Question data-tip data-for={tooltipId} />
      </HStack>
      <Spacer height="x3" />
      <Container id="graph" />

      <ReactTooltip
        id={tooltipId}
        place="right"
        effect="solid"
        multiline={true}
        backgroundColor={theme.colors.primary100}
        textColor={theme.colors.white100}
      >
        {tooltipText()}
      </ReactTooltip>
    </>
  )
}

export default LineChart
