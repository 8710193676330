import React, { useEffect, useState } from 'react'
import { navigate } from '@reach/router'
import styled from 'styled-components'
import { theme } from '../config'
import { Layout, HStack, VStack, Text, Spacer, TextInput } from '../components'
import { Chevron } from '../assets/icons'
import { formatStat } from '../utilities/helperFunctions'
import { useResource } from '../hooks'

const InputContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
`

const Horse = styled.div`
  width: 49.5%;
  background: ${theme.colors.white100};
  border-radius: ${theme.radii.x2};
  padding: ${theme.spacing.x3};
  justify-content: space-between;
  display: flex;
  box-sizing: border-box;
  margin-bottom: ${theme.spacing.x2};
  flex-direction: row;
  box-shadow: ${theme.boxShadow};
  cursor: pointer;
`

const Horses = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  @media (${theme.breakpoints.notSmall}) {
    flex-direction: row;
  }
`

const IndividualHorse = ({ horse }) => {
  return (
    <Horse
      onClick={() =>
        navigate(`/horses/${horse.id}`, {
          state: {
            id: horse.id,
            name: horse.attributes.name,
            athlete: horse.attributes.with,
            elo: horse.attributes.elo,
          },
        })
      }
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '80%',
        }}
      >
        <Spacer size={theme.spacing.x3} />
        <Text size="x5" weight="semibold" cursor="pointer">
          {horse.attributes.name} (Elo: {horse.attributes.elo || 'N/A'})
        </Text>
        <Text size="x4" weight="normal" color="primary30">
          {horse.attributes.with}
        </Text>
        <Spacer height="x2" />
        <HStack
          justify="space-between"
          align="flex-start"
          style={{ width: '80%' }}
        >
          <VStack>
            <Text size="x4" weight="semibold" cursor="pointer">
              {horse.attributes.runs}
            </Text>
            <Text color="primary40" size="x3" weight="normal" cursor="pointer">
              Runs
            </Text>
          </VStack>
          <VStack>
            <Text size="x4" weight="semibold" cursor="pointer">
              {horse.attributes.completions}
            </Text>
            <Text color="primary40" size="x3" weight="normal" cursor="pointer">
              Comps
            </Text>
          </VStack>
          <VStack>
            <Text size="x4" weight="semibold" cursor="pointer">
              {horse.attributes.wins}
            </Text>
            <Text color="primary40" size="x3" weight="normal" cursor="pointer">
              Wins
            </Text>
          </VStack>
          <VStack>
            <Text size="x4" weight="semibold" cursor="pointer">
              {horse.attributes.top5s}
            </Text>
            <Text color="primary40" size="x3" weight="normal" cursor="pointer">
              Top 5s
            </Text>
          </VStack>
          <VStack>
            <Text size="x4" weight="semibold" cursor="pointer">
              {formatStat(horse.attributes.averageFinalScore, 1)}
            </Text>
            <Text color="primary40" size="x3" weight="normal" cursor="pointer">
              Avg FS
            </Text>
          </VStack>
        </HStack>
        <Spacer size={theme.spacing.x3} />
      </div>
      <div style={{ display: 'flex', alignSelf: 'center' }}>
        <Chevron direction="left" />
      </div>
    </Horse>
  )
}

const HorseSearch = () => {
  const [horseName, setHorseName] = useState('')
  const { getAll: getAllHorses, all: horses } = useResource(
    'dashboard/high_performance/search?type=horse'
  )

  useEffect(() => getAllHorses({ authToken: true }), [])

  const filteredArr =
    horses &&
    horses.data.filter((horse) =>
      horse.attributes.name.toLowerCase().includes(horseName.toLowerCase())
    )

  return (
    <Layout>
      {horses ? (
        <>
          <InputContainer>
            <Text size="x4" weight="semibold">
              Search Horses
            </Text>
            <Spacer height="x2" />
            <TextInput
              placeholder="Enter Horse name here"
              onChange={(e) => setHorseName(e.target.value)}
            />
          </InputContainer>
          <Spacer height="x8" />
          <Horses>
            {filteredArr.map((horse, idx) => {
              return <IndividualHorse horse={horse} />
            })}
          </Horses>
        </>
      ) : null}
    </Layout>
  )
}

export default HorseSearch
