import React, { useEffect, useState } from 'react'
import { useResource } from '../hooks'
import envVars from '../utilities/envVars'
import {
  Text,
  Spacer,
  TeamSquadOverview,
  TeamZoneAnalysis,
  LineChart,
  Layout,
  TeamExpecteds,
} from '../components'

const base_url = 'dashboard/high_performance'
const selectYearFromInitial = envVars.currentYear - 1
const selectYearToInitial = envVars.currentYear

const Team = () => {
  // === Nation Elos ================================================================================
  const { getAll: getNationElos, all: nationElos } = useResource(
    `${base_url}/elo_trend` +
    `?year_from=${envVars.currentYear - 11}` +
    `&year_to=${envVars.currentYear}` +
    `&decimal_places=0`
  )

  // No need to add to useEffect as it's parameters will never change
  useEffect(() => {
    getNationElos({ authToken: true })
  }, [])

  // === Squad Overview ===========================================================================
  const [activeTab, setActiveTab] = useState(0)
  const [selectedYearFromOverview, setYearFromOverview] = useState(selectYearFromInitial)
  const [selectedYearToOverview, setYearToOverview] = useState(selectYearToInitial)
  const [selectedLevelOverview, setLevelOverview] = useState(
    envVars.majorClasses
  )

  let overviewLevelsMapped = ''
  selectedLevelOverview.forEach((item) => {
    overviewLevelsMapped = `${overviewLevelsMapped}&er_levels[]=${item.value}`
  })

  const { getAll: getSquadOverview, all: squadOverview } = useResource(
    `${base_url}/squad_overview` +
    `?group_by=${activeTab === 0 ? 'horse' : 'athlete'}` +
    `&year_from=${selectedYearFromOverview}` +
    `&year_to=${selectedYearToOverview}` +
    `${overviewLevelsMapped}` +
    `&decimal_places=0`
  )

  useEffect(() => {
    getSquadOverview({ authToken: true })
  }, [getSquadOverview])

  // === Zone Analysis ===========================================================================
  const [selectedYearFromZone, setYearFromZone] = useState(
    selectYearFromInitial
  )
  const [selectedYearToZone, setYearToZone] = useState(selectYearToInitial)
  const [selectedLevelZone, setLevelZone] = useState(envVars.majorClasses)

  let zoneLevelsMapped = ''
  selectedLevelZone.forEach((item) => {
    zoneLevelsMapped = `${zoneLevelsMapped}&er_levels[]=${item.value}`
  })

  const { getAll: getSquadZones, all: squadZones } = useResource(
    `${base_url}/squad_zone_analysis` +
    `?year_from=${selectedYearFromZone}` +
    `&year_to=${selectedYearToZone}` +
    `${zoneLevelsMapped}` +
    `&group_by=horse` +
    `&decimal_places=0`
  )

  useEffect(() => {
    getSquadZones({ authToken: true })
  }, [getSquadZones])

  // === Horse Expecteds ===========================================================================
  const majorClasses = envVars.majorClasses.map((lvl) => {
    return { title: lvl.key, level: lvl.value }
  })
  const fiveStarClass = majorClasses.find((lvl) => lvl.level === 13)
  const [selectedYearFromExp, setYearFromExp] = useState(selectYearFromInitial)
  const [selectedYearToExp, setYearToExp] = useState(selectYearToInitial)
  const [selectedLevelExp, setLevelExp] = useState(fiveStarClass.level)

  const { getAll: getHorseExpecteds, all: horseExpecteds } = useResource(
    `${base_url}/expected_values_horse` +
    `?year_from=${selectedYearFromExp}` +
    `&year_to=${selectedYearToExp}` +
    `&er_levels[]=${selectedLevelExp}` +
    `&decimal_places=0`
  )

  useEffect(() => {
    getHorseExpecteds({ authToken: true })
  }, [
    getHorseExpecteds,
    selectedYearFromExp,
    selectedYearToExp,
    selectedLevelExp,
  ])

  // === Map out top 3, 6, and 10 ELOs ===========================================================
  const top3 =
    nationElos &&
    nationElos.data.map((item, idx) => {
      return {
        label: new Date(item.attributes.year),
        value: item.attributes.eloTrendData.top03.average,
        subTitle: item.attributes.year,
      }
    })

  const top6 =
    nationElos &&
    nationElos.data.map((item, idx) => {
      return {
        label: new Date(item.attributes.year),
        value: item.attributes.eloTrendData.top06.average,
        subTitle: item.attributes.year,
      }
    })

  const top10 =
    nationElos &&
    nationElos.data.map((item, idx) => {
      return {
        label: new Date(item.attributes.year),
        value: item.attributes.eloTrendData.top10.average,
        subTitle: item.attributes.year,
      }
    })

  const isAthlete = localStorage.getItem('hpAthleteId')

  return (
    <Layout>
      {nationElos && squadZones && squadOverview ? (
        <>
          {isAthlete == 'null' && (
            <>
              <LineChart
                tooltipId="nationElo"
                tooltipText={() => (
                  <Text weight="semibold" size="x2" color="white100">
                    Each horse has an Elo rating which measures their
                    competitiveness. <Spacer height="x2" />
                    Their rating goes up or down after each FEI run (except
                    for WDs before XC). The higher the Elo the better.
                    <Spacer height="x2" />
                    <Text size="x2" weight="semibol" color="secondary100">
                      The top 10% of 4* and 5* horses have Elos of 600+.
                    </Text>
                    <Spacer height="x2" />
                    Your Nation Elo is the average of your nation’s top 3, 6
                    or 10 Elo-rated horses each year (all your nation’s
                    horses, not limited to squad horses).
                  </Text>
                )}
                yMaxVal={800}
                showAnnotation={true}
                subTitleLabel1={'Elo'}
                subTitleLabel2={'Year'}
                title={'Nation Elos'}
                xAxisFormat={'%Y'}
                data={[top3, top6, top10]}
                width={925}
                height={300}
              />
              <Spacer height="x8" />
            </>
          )}
          <Spacer height="x8" />
          <TeamSquadOverview
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            data={squadOverview}
            setYearFrom={setYearFromOverview}
            setYearTo={setYearToOverview}
            setLevel={setLevelOverview}
            selectedLevels={selectedLevelOverview}
            yearFromDropdownInitialItem={selectYearFromInitial}
            yearToDropdownInitialItem={selectYearToInitial}
          />
          <Spacer height="x8" />
          <Spacer height="x8" />
          <TeamExpecteds
            data={horseExpecteds}
            setYearFrom={setYearFromExp}
            setYearTo={setYearToExp}
            setLevel={setLevelExp}
            selectedLevels={selectedLevelExp}
            yearFromDropdownInitialItem={selectYearFromInitial}
            yearToDropdownInitialItem={selectYearToInitial}
          />
          <Spacer height="x8" />
          <Spacer height="x8" />
          <TeamZoneAnalysis
            data={squadZones}
            setYearFrom={setYearFromZone}
            setYearTo={setYearToZone}
            setLevel={setLevelZone}
            selectedLevels={selectedLevelZone}
            showColumnDistribution
            yearFromDropdownInitialItem={selectYearFromInitial}
            yearToDropdownInitialItem={selectYearToInitial}
          />
        </>
      ) : null}
    </Layout>
  )
}

export default Team
