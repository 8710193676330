import React from 'react'
import { Layout } from '../components'
import { teamPerfAuth } from '../utilities/teamPerfAuth'

class EloRatings extends React.Component {
  render() {
    teamPerfAuth()
      .then((response) => console.log(response))
      .catch((error) => console.log(error))

    return (
      <Layout>
        <div
          style={{
            height: '2000px',
            margin: '0 auto',
            backgroundColor: 'transparent',
          }}
        >
          <iframe
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            width="100%"
            height="100%"
            scrolling="no"
            frameBorder="0"
            src="https://yulixmp5sfcu1tamrgehdfd1idlpygp1.netlify.app/"
          />
        </div>
      </Layout>
    )
  }
}

export default EloRatings
