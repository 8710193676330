import React, { useMemo } from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { navigate } from '@reach/router'

import Table from './Table'
import Spacer from './Spacer'
import HStack from './HStack'
import Text from './Text'
import Dropdown from './Dropdown'
import MultiSelectDropdown from './MultiSelectDropdown'
import Question from '../assets/icons/Question'
import envVars from '../utilities/envVars'
import { theme } from '../config'

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const DropdownContainer = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const TeamZoneAnalysis = ({
  data,
  setYearFrom,
  setYearTo,
  setLevel,
  selectedLevels,
  yearFromDropdownInitialItem,
  yearToDropdownInitialItem,
}) => {
  const removeClass = (index) => {
    const newArr = [...selectedLevels]
    newArr.splice(index, 1)
    setLevel(newArr)
  }

  const addNewClass = level => {
    const someArr = selectedLevels.map(lvl => lvl.value)
    const isDuplicate = someArr.includes(level.value)
    if (!isDuplicate) {
      setLevel([...selectedLevels, level])
    }
  }

  const newData = useMemo(
    () =>
      data.data.map((item) => {
        return {
          ...item,
          zoneScore: parseInt(item.attributes.zoneScore),
        }
      }),
    [data]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Horse',
        textAlign: 'left',
        accessor: 'attributes.name',
        isBold: true,
        cursor: 'pointer',
        Cell: (props) => (
          <div
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={() => {
              localStorage.setItem('navTab', 1)
              navigate(`/horses/${props.row.original.attributes.groupById}`, {
                state: {
                  id: props.row.original.attributes.groupById,
                  name: props.value,
                  athlete: props.row.original.attributes.currentHpAthlete,
                  elo: props.row.original.attributes.currentElo,
                },
              })
            }}
          >
            {props.value}
          </div>
        ),
      },
      {
        Header: 'Runs',
        accessor: 'attributes.runs',
        sortType: 'alphanumericFalsyLast',
        Cell: (props) => <div>{props.value || '-'}</div>,
      },
      {
        Header: 'Zone Score',
        accessor: 'attributes.zoneScore',
        sortType: 'alphanumericFalsyLast',
        Cell: (props) => <div>{props.value || '-'}</div>,
      },
      {
        Header: 'Sub 28 (+6)',
        accessor: 'attributes.zone01.value',
        sortType: 'alphanumericFalsyLast',
        bg: theme.colors.green80,
        total: 'attributes.runs',
        Cell: (props) => <div>{props.value || '-'}</div>,
      },
      {
        Header: '28-32 (+5)',
        sortType: 'alphanumericFalsyLast',
        accessor: 'attributes.zone02.value',
        bg: theme.colors.green80,
        total: 'attributes.runs',
        Cell: (props) => <div>{props.value || '-'}</div>,
      },
      {
        Header: '32-36 (+4)',
        accessor: 'attributes.zone03.value',
        bg: theme.colors.green80,
        total: 'attributes.runs',
        sortType: 'alphanumericFalsyLast',
        Cell: (props) => <div>{props.value || '-'}</div>,
      },
      {
        Header: '36-40 (+3)',
        accessor: 'attributes.zone04.value',
        bg: theme.colors.green80,
        total: 'attributes.runs',
        sortType: 'alphanumericFalsyLast',
        Cell: (props) => <div>{props.value || '-'}</div>,
      },
      {
        Header: '40-45 (+2)',
        accessor: 'attributes.zone05.value',
        bg: theme.colors.secondary80,
        total: 'attributes.runs',
        sortType: 'alphanumericFalsyLast',
        Cell: (props) => <div>{props.value || '-'}</div>,
      },
      {
        Header: '45-50 (+1)',
        accessor: 'attributes.zone06.value',
        bg: theme.colors.secondary80,
        total: 'attributes.runs',
        sortType: 'alphanumericFalsyLast',
        Cell: (props) => <div>{props.value || '-'}</div>,
      },
      {
        Header: '50-60 (+0)',
        accessor: 'attributes.zone07.value',
        bg: theme.colors.secondary80,
        total: 'attributes.runs',
        sortType: 'alphanumericFalsyLast',
        Cell: (props) => <div>{props.value || '-'}</div>,
      },
      {
        Header: '60+ (-2)',
        accessor: 'attributes.zone08.value',
        bg: theme.colors.red80,
        total: 'attributes.runs',
        sortType: 'alphanumericFalsyLast',
        Cell: (props) => <div>{props.value || '-'}</div>,
      },
      {
        Header: 'DNF (-3)',
        accessor: 'attributes.zone09.value',
        bg: theme.colors.red80,
        total: 'attributes.runs',
        sortType: 'alphanumericFalsyLast',
        Cell: (props) => <div>{props.value || '-'}</div>,
      },
    ],
    []
  )
  const levels = [
    { key: 'CCI5*-L', value: 13 },
    { key: 'CCI4*-L', value: 12 },
    { key: 'CCI4*-S', value: 11 },
    { key: 'CCI3*-L', value: 9 },
    { key: 'CCI3*-S', value: 8 },
    { key: 'CCI2*-L', value: 6 },
    { key: 'CCI2*-S', value: 5 },
    { key: 'CCI1*', value: 3 },
  ]
  return (
    <>
      <TitleContainer>
        <HStack align="center">
          <Text size="x5" weight="semibold">
            Squad Zone Analysis
          </Text>
          <Spacer width="x4" />
          <Question data-tip data-for="teamZA" />
        </HStack>
        <DropdownContainer>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text size="x4" weight="semibold">
              Year From
            </Text>
            <Spacer height="x1" />
            <Dropdown
              onChange={(year) => setYearFrom(year)}
              readOnly={true}
              items={envVars.years}
              itemToString={(year) => year}
              initialItem={yearFromDropdownInitialItem}
              handleFilter={() => () => true}
              displaySuggestion={(year) => (
                <Text
                  size={theme.fontScale.x2}
                  weight={theme.fontWeight.normal}
                >
                  {year}
                </Text>
              )}
            />
          </div>
          <Spacer width="x4" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text size="x4" weight="semibold">
              Year To
            </Text>
            <Spacer height="x1" />
            <Dropdown
              onChange={(year) => setYearTo(year)}
              readOnly={true}
              items={envVars.years}
              itemToString={(year) => year}
              initialItem={yearToDropdownInitialItem}
              handleFilter={() => () => true}
              displaySuggestion={(year) => (
                <Text
                  size={theme.fontScale.x2}
                  weight={theme.fontWeight.normal}
                >
                  {year}
                </Text>
              )}
            />
          </div>
          <Spacer width="x4" />
          <div
            style={{ width: '49%', display: 'flex', flexDirection: 'column' }}
          >
            <Text size="x4" weight="semibold">
              Select Level
            </Text>
            <Spacer height="x1" />
            <MultiSelectDropdown
              onChange={(level) => addNewClass(level)}
              readOnly={true}
              items={envVars.allClasses}
              itemToString={(level) => level.key}
              selectedItems={selectedLevels}
              initialItem={selectedLevels[0]}
              handleFilter={() => () => true}
              removeItem={(index) => removeClass(index)}
              displaySuggestion={(level) => (
                <Text
                  size={theme.fontScale.x2}
                  weight={theme.fontWeight.normal}
                >
                  {level.key}
                </Text>
              )}
            />
          </div>
        </DropdownContainer>
      </TitleContainer>
      <Spacer height="x4" />
      <Table
        title="Squad Zone Analysis"
        columns={columns}
        data={newData}
        showColumnDistribution={true}
        defaultSort={'attributes.zoneScore'}
      />

      <ReactTooltip
        id="teamZA"
        place="right"
        effect="solid"
        multiline={true}
        backgroundColor={theme.colors.primary100}
        textColor={theme.colors.white100}
      >
        <Text weight="semibold" size="x2" color="white100">
          <HStack>
            You can
            <Spacer width="x1" />
            <Text size="x2" color="secondary100" weight="semibold">
              filter
            </Text>
            <Spacer width="x1" />
            by year and level.
          </HStack>
          <Spacer height="x2" />
          <HStack>
            You can
            <Spacer width="x1" />
            <Text size="x2" color="secondary100" weight="semibold">
              sort
            </Text>
            <Spacer width="x1" />
            by any column by clicking on the column header.
          </HStack>
          <Spacer height="x2" />
          Zone Analysis tells you how many times a horse has finished in the
          given scoring zone (at the selected year and levels).
          <Spacer height="x2" />
          Zone Score: Point values are assigned to each zone, e.g. a sub-28
          finish rewards a horse +6 points.
          <Spacer height="x2" />
          Zone Score is the total accumulated zone points (at the selected year
          and levels). The higher the Zone Score the better.
        </Text>
      </ReactTooltip>
    </>
  )
}

export default TeamZoneAnalysis
