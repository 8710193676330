import { camelizeKeys, decamelizeKeys } from 'humps'
import { decode, encode } from 'base-64'

if (!global.btoa) {
  global.btoa = encode
}

if (!global.atob) {
  global.atob = decode
}

const buildFetchOptions = async (method, options) => {
  const fetchOptions = {
    method,
    headers: {
      Accept: 'application/json',
    },
  }

  const methodsWithPayload = ['POST', 'PUT']
  if (methodsWithPayload.includes(method)) {
    fetchOptions.headers['Content-Type'] = 'application/json'
    fetchOptions.body = JSON.stringify(decamelizeKeys(options.payload))
  }

  if (options.authToken) {
    const token = await localStorage.getItem('accessToken')
    fetchOptions.headers['Authorization'] = `Bearer ${token}`
  }

  return fetchOptions
}

const fetcher = async (
  method,
  url,
  onSuccess,
  onError,
  options = { authToken: false },
  setSuccess
) => {
  try {
    const newUrl =
      localStorage.getItem('erNationality') !== null
        ? url + `&nf=${localStorage.getItem('erNationality')}`
        : ''
    const fetchOptions = await buildFetchOptions(method, options)
    console.log('FETCH OPTIONS', fetchOptions)
    const response = await fetch(newUrl, fetchOptions)
    const responseJSON = await response.json()
    if (response.status === 401) {
      throw new Error('There was an issue logging you in')
    }
    const processedJSON =
      response.status !== 204 ? camelizeKeys(responseJSON) : null

    // console.log('RESPONSE', processedJSON)
    // console.log('RESPONSE STATUS', response.status)
    response.status !== 204 && onSuccess(processedJSON)
    setSuccess(true)
  } catch (e) {
    onError(e)
  }
}

const get = async (url, onSuccess, onError, options, setSuccess) => {
  await fetcher('GET', url, onSuccess, onError, { ...options }, setSuccess)
}

const post = async (url, payload, onSuccess, onError, options, setSuccess) =>
  await fetcher(
    'POST',
    url,
    onSuccess,
    onError,
    { ...options, payload },
    setSuccess
  )

const put = async (url, payload, onSuccess, onError, options, setSuccess) =>
  await fetcher(
    'PUT',
    url,
    onSuccess,
    onError,
    { ...options, payload },
    setSuccess
  )

const destroy = async (url, onError, options) => {
  await fetcher('DELETE', url, () => console.log('noop'), onError, options)
}

export default { get, post, put, destroy }
