import axios from 'axios'
const instance = () => {
  const HOST_URL = 'https://team-performance-dash.herokuapp.com'
  const options = {
    baseURL: `${HOST_URL}`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }
  const client = axios.create(options)
  return client
}

export const teamPerfAuth = () => {
  return instance().post('/login', {
    username: 'test',
    password: 'test',
  })
}
