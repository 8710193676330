const currentYear = new Date().getFullYear();
const startYear = 2010;

let yearFrom = startYear;
let yearTo = currentYear;
const years = [];

while (yearTo >= yearFrom) {
    years.push(yearTo);
    yearTo += -1
};

const allClassLevels = [13, 12, 11, 9, 8, 6, 5, 3];
const majorClassLevels = [13, 12, 11];

const allClasses = [
    { key: "All Levels", value: allClassLevels },
    { key: "CCI5*-L", value: 13 },
    { key: "CCI4*-L", value: 12 },
    { key: "CCI4*-S", value: 11 },
    { key: "CCI3*-L", value: 9 },
    { key: "CCI3*-S", value: 8 },
    { key: "CCI2*-L", value: 6 },
    { key: "CCI2*-S", value: 5 },
    { key: "CCI1*-U", value: 3 },
];

const majorClassesFilter = (c) => { return majorClassLevels.includes(c.value) };

const envVars = {
    startYear: startYear,
    currentYear: currentYear,
    years: years,
    allClassLevels: allClassLevels,
    allClasses: allClasses,
    majorClassLevels: majorClassLevels,
    majorClasses: allClasses.filter(majorClassesFilter)
};

export default envVars;
