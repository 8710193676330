import React from 'react'
import { Layout, Header, Navigation } from './components'
import { differenceInDays } from 'date-fns'
import { navigate } from '@reach/router'
import { redirectTo } from '@reach/router'
function App() {
  const tokenCreated = localStorage.getItem('tokenCreated')
  const token = localStorage.getItem('accessToken')

  // if (token && tokenCreated && differenceInDays(tokenCreated, Date.now() < 1)) {
  //   navigate('/login')
  // }
  React.useEffect(() => {
    navigate('/login')
  }, [])

  return (
    <div>
      <Navigation />
    </div>
  )
}

export default App
