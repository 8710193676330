import React from 'react'
import { Layout } from '../components'
import { zoneAnalysisAuth } from '../utilities/zoneAnalysisAuth'

class ZoneAnalysis extends React.Component {
  render() {
    // zoneAnalysisAuth()
    //   .then((response) => console.log(response))
    //   .catch((error) => console.log(error))
    return (
      <Layout>
        <div
          style={{
            height: '1400px',
            margin: '0 auto',
            backgroundColor: 'transparent',
          }}
        >
          <iframe
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-downloads"
            width="100%"
            height="100%"
            scrolling="no"
            frameBorder="0"
            src="https://zoneanalysis-dash.herokuapp.com/zone_analysis"
          />
        </div>
      </Layout>
    )
  }
}

export default ZoneAnalysis
