import * as React from 'react'

function Profile(props) {
  return (
    <svg
      width={36}
      height={36}
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{'\uDBC0\uDE6D'}</title>
      <text
        transform="translate(-1311 -7)"
        fill="#1A3852"
        fillRule="evenodd"
        fontFamily="SFCompactDisplay-Regular, SF Compact Display"
        fontSize={36}
      >
        <tspan x={1309.487} y={37}>
          􀉭
        </tspan>
      </text>
    </svg>
  )
}

export default Profile
