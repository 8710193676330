import { useState, useCallback } from 'react'

import http from '../utilities/http'

const defaultOptions = { authToken: false }
const serverUrl = 'https://eventing.api.equiratings.com/v1/'

const buildUrl = (key) =>
  (/(http(s?)):\/\//gi.test(key) ? key : serverUrl + key)

function useResource(key) {
  const resourceUrl = buildUrl(key)

  // Local state
  const [all, setAll] = useState()
  const [one, setOne] = useState()
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)

  // Public functions
  const getAll = useCallback(
    async function (options = defaultOptions) {
      await http.get(resourceUrl, setAll, setError, options, setSuccess)
      console.log('HERES THE ALL', all)
    },
    [resourceUrl]
  )

  const getOne = useCallback(
    async function (id, options = defaultOptions) {
      await http.get(resourceUrl + '/' + id, setOne, setError, options, setSuccess)
    },
    [resourceUrl]
  )

  const create = useCallback(
    async function (payload, options = defaultOptions) {
      function setData(item) {
        setOne(item)
        setAll(all ? [...all, item] : [item])
      }

      setData(payload)

      await http.post(
        resourceUrl,
        payload,
        setData,
        setError,
        options,
        setSuccess
      )
    },
    [all, resourceUrl]
  )

  const update = useCallback(
    async function (id, payload, options = defaultOptions) {
      function setData(item) {
        setOne(item)
        setAll(all ? [...all, item] : [item])
      }
      setData(payload)
      await http.put(
        resourceUrl + '/' + id,
        payload,
        setData,
        setError,
        options,
        setSuccess
      )
    },
    [all, resourceUrl]
  )

  const remove = useCallback(
    async function (id, payload, options = defaultOptions) {
      function setData(item) {
        setOne(item)
        setAll(all ? all.filter((i) => id !== i.id) : [])
      }
      setData(payload)
      await http.destroy(resourceUrl + '/' + id, setError, options, setSuccess)
    },
    [all, resourceUrl]
  )

  // Public API
  return {
    all,
    one,
    setOne,
    setAll,
    error,
    setError,
    success,
    setSuccess,
    getAll,
    getOne,
    create,
    update,
    remove,
  }
}

export default useResource
