import { createElement as h } from 'react'
import styled from 'styled-components'

import { theme } from '../config'

export const withDynamicTag = (Component) => {
  const bucket = Object.create(null)

  const DynamicTag = (props) => {
    const { tag } = props

    if (typeof tag !== 'string' || !styled.hasOwnProperty(tag)) {
      return h(Component, props)
    }

    if (bucket[tag] === undefined) {
      bucket[tag] = Component.withComponent(tag)
    }

    return h(bucket[tag], props)
  }

  const name = Component.displayName || Component.constructor.name

  if (name) {
    DynamicTag.displayName = `DynamicTag(${name})`
  }

  return DynamicTag
}

const getMobileTextSize = (size) => {
  if (size == 'x7') {
    return 'x6'
  } else if (size == 'x6') {
    return 'x5'
  } else if (size == 'x5') {
    return 'x4'
  } else if (size == 'x4') {
    return 'x3'
  } else {
    return 'x2'
  }
}

const TextBase = styled.p.attrs((props) => ({
  'aria-label': props.children,
}))`
  cursor: ${({ cursor }) => cursor || 'auto'};
  color: ${({ color }) => theme.colors[color]};
  font-size: ${({ size }) => theme.fontScale[size]};
  font-weight: ${({ weight }) => theme.fontWeight[weight]};
  text-align: ${({ align }) => align || 'left'};
  line-height: ${({ lineHeight }) => lineHeight || 1.5};
  margin: 0;
  padding: 0;
`

export const Strong = styled.strong`
  font-weight: ${theme.fontWeight.bold};
`

export const Em = styled.em`
  text-decoration: italic;
`

TextBase.defaultProps = {
  size: 'x3',
  weight: 'normal',
  color: 'primary100',
  align: 'left',
}

export default withDynamicTag(TextBase)
