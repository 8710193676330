import React, { useEffect, useState, useMemo } from 'react'
import { navigate } from '@reach/router'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'

import { theme } from '../config'

import {
  HStack,
  Dropdown,
  MultiSelectDropdown,
  Text,
  TextInput,
  Spacer,
  Table,
  Layout,
} from '../components'
import Question from '../assets/icons/Question'

import { useResource } from '../hooks'
import { formatStat, formatToPercentage } from '../utilities/helperFunctions'
import Chevron from '../assets/icons/Chevron'
import envVars from '../utilities/envVars'

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: flex-end;
`

const DropdownContainer = styled.div`
  width: 80%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

const ResultsView = () => {
  const removeClass = (index, classList, setClassListFunc) => {
    if (classList.length > 1) {
      const newArr = [...classList]
      newArr.splice(index, 1)
      setClassListFunc(newArr)
    }
  }

  const addNewClass = (level, classList, setClassListFunc) => {
    const someArr = classList.map(lvl => lvl.value)
    const isDuplicate = someArr.includes(level.value)
    if (!isDuplicate) {
      setClassListFunc([...classList, level])
    }
  }

  const base_url = 'dashboard/high_performance';
  const selectYearFromInitial = envVars.currentYear;
  const selectYearToInitial = envVars.currentYear;


  // === VENUES ==========================================================================================
  const [activeVenueFilter, setVenueFilter] = useState('')
  const [selectedYearFrom, setYearFrom] = useState(selectYearFromInitial)
  const [selectedYearTo, setYearTo] = useState(selectYearToInitial)
  const [selectedLevel, setLevel] = useState(envVars.majorClasses)

  let levelsMapped = ''
  selectedLevel.forEach((item) => {
    levelsMapped = `${levelsMapped}&er_levels[]=${item.value}`
  })

  let nameFilter =
    activeVenueFilter && activeVenueFilter.length >= 3
      ? `&venues_filter[name_contains]=${activeVenueFilter}`
      : ''

  const { getAll: getVenues, all: venues } =
    useResource(
      `${base_url}/competitions_analysis` +
      `?group_by=venue` +
      `&year_from=${selectedYearFrom}` +
      `&year_to=${selectedYearTo}` +
      `${levelsMapped}` +
      `${nameFilter}` +
      `&decimal_places=1`
    )

  useEffect(() => {
    getVenues({ authToken: true })
  }, [getVenues])

  // === COMPETITIONS ==========================================================================================
  const [activeCompFilter, setCompFilter] = useState('')
  const [selectedYearFromComp, setYearFromComp] = useState(selectYearFromInitial)
  const [selectedYearToComp, setYearToComp] = useState(selectYearToInitial)
  const [selectedLevelComp, setLevelComp] = useState(envVars.majorClasses)

  let levelsMappedComp = ''
  selectedLevelComp.forEach((item) => {
    levelsMappedComp = `${levelsMappedComp}&er_levels[]=${item.value}`
  })

  let compNameFilter =
    activeCompFilter && activeCompFilter.length >= 3
      ? `&venues_filter[name_contains]=${activeCompFilter}`
      : ''

  const { getAll: getCompetitions, all: competitions } =
    useResource(
      `${base_url}/competitions_analysis` +
      `?group_by=competition` +
      `&year_from=${selectedYearFromComp}` +
      `&year_to=${selectedYearToComp}` +
      `${levelsMappedComp}` +
      `${compNameFilter}` +
      `&decimal_places=1`
    )

  useEffect(() => {
    getCompetitions({ authToken: true })
  }, [getCompetitions])

  // ===========================================================================================================

  const competitionData = useMemo(
    () => (competitions && competitions.data) || []
  )

  const competitionColumns = useMemo(() => [
    {
      Header: 'Venue',
      textAlign: 'left',
      accessor: 'attributes.venue',
      isBold: true,
      cursor: 'pointer',
      Cell: (props) => <div>{props.value}</div>,
    },
    {
      Header: 'Date',
      accessor: 'attributes.date',
    },
    { Header: 'Class', accessor: 'attributes.class' },
    {
      Header: 'Starters',
      accessor: 'attributes.starterCountAvg',
      sortType: 'alphanumericFalsyLast',
    },
    {
      Header: 'Field Strength',
      accessor: 'attributes.fieldStrengthAvg',
      sortType: 'alphanumericFalsyLast',
      Cell: (props) => <div>{props.value}</div>,
    },
    {
      Header: 'Winning HPR',
      accessor: 'attributes.winningHprAvg',
      sortType: 'alphanumericFalsyLast',
      Cell: (props) => <div>{props.value || '-'}</div>,
    },
    {
      Header: 'ADS',
      sortType: 'alphanumericFalsyLast',
      accessor: 'attributes.edrDiffAvg',
      Cell: (props) => <div>{formatStat(props.value, 1)}</div>,
    },
    {
      Header: 'SJS',
      sortType: 'alphanumericFalsyLast',
      accessor: 'attributes.esjDiffAvg',
      Cell: (props) => <div>{formatStat(props.value, 1)}</div>,
    },
    {
      Header: 'XJS',
      sortType: 'alphanumericFalsyLast',
      accessor: 'attributes.excjDiffAvg',
      Cell: (props) => <div>{formatToPercentage(props.value, 2)}</div>,
    },
    {
      Header: 'XTS',
      accessor: 'attributes.exct50DiffAvg',
      sortType: 'alphanumericFalsyLast',
      Cell: (props) => <div>{formatStat(props.value, 1)}</div>,
    },
    {
      Header: '',
      accessor: 'attributes.competitionId',
      Cell: (props) => (
        <div
          onClick={() =>
            navigate(`/venues/competition/${props.value}`, {
              state: {
                id: props.value,
                name: `${props.row.original.attributes.venue} ${props.row.original.attributes.class}`,
                date: props.row.original.attributes.date,
              },
            })
          }
          style={{ paddingRight: 10, paddingLeft: 10 }}
        >
          <Chevron direction="left" />
        </div>
      ),
    },
  ])

  const venueData = useMemo(() => (venues && venues.data) || [], [venues])

  const venueColumns = useMemo(() => [
    {
      Header: 'Venue',
      textAlign: 'left',
      accessor: 'attributes.venue',
      isBold: true,
      cursor: 'pointer',
      Cell: (props) => <div>{props.value}</div>,
    },
    {
      Header: 'Num of Comps',
      accessor: 'attributes.compCount',
      sortType: 'alphanumericFalsyLast',
    },
    {
      Header: 'Avg Starters',
      accessor: 'attributes.starterCountAvg',
      sortType: 'alphanumericFalsyLast',
    },
    {
      Header: 'Avg Field Strength',
      accessor: 'attributes.fieldStrengthAvg',
      sortType: 'alphanumericFalsyLast',
      Cell: (props) => <div>{props.value}</div>,
    },
    {
      Header: 'Winning HPR',
      accessor: 'attributes.winningHprAvg',
      sortType: 'alphanumericFalsyLast',
      Cell: (props) => <div>{props.value || '-'}</div>,
    },
    {
      Header: 'ADS',
      sortType: 'alphanumericFalsyLast',
      accessor: 'attributes.edrDiffAvg',
      Cell: (props) => <div>{formatStat(props.value, 1)}</div>,
    },
    {
      Header: 'SJS',
      accessor: 'attributes.esjDiffAvg',
      sortType: 'alphanumericFalsyLast',
      Cell: (props) => <div>{formatStat(props.value, 1)}</div>,
    },
    {
      Header: 'XJS',
      accessor: 'attributes.excjDiffAvg',
      Cell: (props) => <div>{formatToPercentage(props.value, 0)}</div>,
    },
    {
      Header: 'XTS',
      accessor: 'attributes.exct50DiffAvg',
      sortType: 'alphanumericFalsyLast',
      Cell: (props) => <div>{formatStat(props.value, 1)}</div>,
    },
  ])
  const levels = [
    { key: 'CCI5*-L', value: 13 },
    { key: 'CCI4*-L', value: 12 },
    { key: 'CCI4*-S', value: 11 },
  ]
  return (
    <Layout>
      <TitleContainer>
        <Text size="x5" weight="semibold">
          Competition Analysis
        </Text>
        <DropdownContainer>
          <TextInput
            placeholder="Search for Venue"
            onChange={(e) => setCompFilter(e.target.value.toLowerCase())}
            style={{ width: '25%' }}
          />
          <Spacer width="x4" />
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '15%' }}
          >
            <Text size="x4" weight="semibold">
              Year From
            </Text>
            <Spacer height="x1" />
            <Dropdown
              onChange={(year) => setYearFromComp(year)}
              readOnly={true}
              items={envVars.years}
              itemToString={(year) => year}
              initialItem={selectYearFromInitial}
              handleFilter={() => () => true}
              displaySuggestion={(year) => (
                <Text
                  size={theme.fontScale.x2}
                  weight={theme.fontWeight.normal}
                >
                  {year}
                </Text>
              )}
            />
          </div>
          <Spacer width="x4" />
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '15%' }}
          >
            <Text size="x4" weight="semibold">
              Year To
            </Text>
            <Spacer height="x1" />
            <Dropdown
              onChange={(year) => setYearToComp(year)}
              readOnly={true}
              items={envVars.years}
              itemToString={(year) => year}
              initialItem={selectYearToInitial}
              handleFilter={() => () => true}
              displaySuggestion={(year) => (
                <Text
                  size={theme.fontScale.x2}
                  weight={theme.fontWeight.normal}
                >
                  {year}
                </Text>
              )}
            />
          </div>
          <Spacer width="x4" />
          <div
            style={{ width: '35%', display: 'flex', flexDirection: 'column' }}
          >
            <Text size="x4" weight="semibold">
              Select Level
            </Text>
            <Spacer height="x1" />
            <MultiSelectDropdown
              onChange={(level) => addNewClass(level, selectedLevelComp, setLevelComp)}
              readOnly={true}
              items={envVars.allClasses}
              itemToString={(level) => level.key}
              selectedItems={selectedLevelComp}
              initialItem={envVars.majorClasses}
              handleFilter={() => () => true}
              removeItem={(idx) => removeClass(idx, selectedLevelComp, setLevelComp)}
              displaySuggestion={(level) => (
                <Text
                  size={theme.fontScale.x2}
                  weight={theme.fontWeight.normal}
                >
                  {level.key}
                </Text>
              )}
            />
          </div>
        </DropdownContainer>
      </TitleContainer>
      <Spacer height="x8" />
      <Table
        data={competitionData}
        columns={competitionColumns}
        defaultSort={'attributes.date'}
      />
      <Spacer height="x16" />
      <TitleContainer>
        <HStack align="center">
          <Text size="x5" weight="semibold">
            Venue Analysis
          </Text>
          <Spacer width="x4" />
          <Question data-tip data-for="venue" />
        </HStack>
        <DropdownContainer>
          <TextInput
            placeholder="Search for Venue"
            onChange={(e) => {
              setVenueFilter(e.target.value.toLowerCase())
            }}
            style={{ width: '25%' }}
          />
          <Spacer width="x4" />
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '15%' }}
          >
            <Text size="x4" weight="semibold">
              Year From
            </Text>
            <Spacer height="x1" />
            <Dropdown
              onChange={(year) => setYearFrom(year)}
              readOnly={true}
              items={envVars.years}
              itemToString={(year) => year}
              initialItem={selectYearFromInitial}
              handleFilter={() => () => true}
              displaySuggestion={(year) => (
                <Text
                  size={theme.fontScale.x2}
                  weight={theme.fontWeight.normal}
                >
                  {year}
                </Text>
              )}
            />
          </div>
          <Spacer width="x4" />
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '15%' }}
          >
            <Text size="x4" weight="semibold">
              Year To
            </Text>
            <Spacer height="x1" />
            <Dropdown
              onChange={(year) => setYearTo(year)}
              readOnly={true}
              items={envVars.years}
              itemToString={(year) => year}
              initialItem={selectYearToInitial}
              handleFilter={() => () => true}
              displaySuggestion={(year) => (
                <Text
                  size={theme.fontScale.x2}
                  weight={theme.fontWeight.normal}
                >
                  {year}
                </Text>
              )}
            />
          </div>
          <Spacer width="x4" />
          <div
            style={{ width: '35%', display: 'flex', flexDirection: 'column' }}
          >
            <Text size="x4" weight="semibold">
              Select Level
            </Text>
            <Spacer height="x1" />
            <MultiSelectDropdown
              onChange={(level) => addNewClass(level, selectedLevel, setLevel)}
              readOnly={true}
              items={envVars.allClasses}
              itemToString={(level) => level.key}
              selectedItems={selectedLevel}
              initialItem={envVars.majorClasses}
              handleFilter={() => () => true}
              removeItem={(idx) => removeClass(idx, selectedLevel, setLevel)}
              displaySuggestion={(level) => (
                <Text
                  size={theme.fontScale.x2}
                  weight={theme.fontWeight.normal}
                >
                  {level.key}
                </Text>
              )}
            />
          </div>
        </DropdownContainer>
      </TitleContainer>
      <Spacer height="x8" />
      <Table
        data={venueData}
        columns={venueColumns}
        defaultSort={'attributes.fieldStrengthAvg'}
      />
      <Spacer height="x4" />
      <ReactTooltip
        id="venue"
        place="right"
        effect="solid"
        multiline={true}
        backgroundColor={theme.colors.primary100}
        textColor={theme.colors.white100}
      >
        <Text weight="semibold" size="x2" color="white100">
          <HStack align="center">
            You can
            <Spacer width="x1" />
            <Text size="x2" color="secondary100" weight="semibold">
              filter by year and level
            </Text>
            <Spacer width="x1" />
            and search for a specific venue.
          </HStack>
          <Spacer height="x2" />
          <HStack align="center">
            You can
            <Spacer width="x1" />
            <Text size="x2" color="secondary100" weight="semibold">
              sort
            </Text>
            <Spacer width="x1" />
            by any column by clicking on the column header.
          </HStack>
          <Spacer height="x2" />
          <HStack>
            <Text size="x2" color="secondary100" weight="semibold">
              Avg Field Strength:
            </Text>
            <Spacer width="x1" />
            Field Strength is the average of the top 20 Elo ratings* coming into
            a single competition. We show you the average Field Strength across
            all competitions at that venue (within the chosen years and levels).
          </HStack>
          <Spacer height="x2" />
          <HStack>
            <Text size="x2" color="secondary100" weight="semibold">
              Avg Winning HPR:
            </Text>
            <Spacer width="x1" />
            Each run gets an EquiRatings High Performance Rating (HPR),
            thecloser to 100 the better. We show you the average Winning HPR
            across all competitions at that venue (within the chosen years and
            levels).
          </HStack>
          <Spacer height="x2" />
          <HStack>
            <Text size="x2" color="secondary100" weight="semibold">
              Avg ADS:
            </Text>
            <Spacer width="x1" />
            ADS is the difference between actual DR scores and expected DR
            scores at a single competition. Here, a positive number means actual
            DR scores at a venuewere, on average, higher than expected (and vice
            versa).
          </HStack>
          <Spacer height="x2" />
          <HStack>
            <Text size="x2" color="secondary100" weight="semibold">
              Avg XJS:
            </Text>
            <Spacer width="x1" />
            is the difference between the actual XCJ clear rate and the expected
            XCJ clear rate at a single competition. Here, a positive number
            means the actual XCJ clear rates at a venue were, on average, higher
            than expected (and vice versa).
          </HStack>
          <Spacer height="x2" />
          <HStack>
            <Text size="x2" color="secondary100" weight="semibold">
              Avg XTS:
            </Text>
            <Spacer width="x1" />
            XTS is the difference between the actual XCT penalties and the
            expected XCT penalties at a single competition (based on the top 50%
            of the field). Here, a positive number means the actual XCT
            penalties at a venue were, on average, higher than expected (and
            vice versa).
          </HStack>
          <Spacer height="x2" />
          <HStack>
            <Text size="x2" color="secondary100" weight="semibold">
              Avg SJS:
            </Text>
            <Spacer width="x1" />
            SJS is the difference between the actual SJJ penalties and the
            expected SJJ penalties at a single competition. Here, a positive
            number means the actual SJJ penalties at a venue were, on average,
            higher than expected (and vice versa).
          </HStack>
          <Spacer height="x2" />
        </Text>
      </ReactTooltip>
    </Layout>
  )
}

export default ResultsView
